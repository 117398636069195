import React, { useCallback, useMemo } from 'react';
import { Col, Form, Input, InputNumber, Modal, Row, Select, Skeleton, Tabs, Empty } from 'antd';
import { UserDeleteOutlined, IdcardOutlined, SyncOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Row as TableRow } from 'react-table';
import { isEmpty } from 'lodash';

// Types
import { FormOptions, UseFormProps } from '../../types/Table';

// Models
import { Area, AreaReader } from '../../models/Area';
import { AreaTypes } from '../../models/enums/AreaTypes';

// Hooks
import { useAppDispatch, useAppSelector } from '../App/useRedux';

// Actions
import {
  createArea,
  deleteArea,
  deleteAreas,
  updateArea,
  assignReaders,
  syncReaders,
} from '../../store/Areas/Areas.redux';

// Components
import { Translated } from '../../components/UI/Core';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { LinkedTreeForm } from '../../components/UI/Tree/LinkedTreeForm';
import { IconButton } from '../../components/UI/Button/IconButton';
import { Widget } from '../../components/UI/Widget/Widget';

// Utils
import { getAreaReadersTreeItems } from '../../constants/Utils/TreeFunctions';
import { getTreeCheckedChildrenIds, getTreeChildren } from '../../constants/Utils/UIFunctions';

const { confirm } = Modal;
const { Option } = Select;

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// Styled
const StyledWidget = styled(Widget)`
  & .ant-card-body {
    padding: 0;
  }
`;
const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav-wrap {
    margin-left: 24px;
  }

  & .ant-tabs-nav {
    height: 58px;
  }

  & .ant-tabs-content {
    padding: 0px 24px 16px;
  }
`;

// Props
interface AreasReduxProps {
  isEditing?: boolean;
  initialValues?: Store;
}

interface AssignReadersConfirmProps {
  areaReaders: Array<AreaReader>;
  areaId: string;
}

// Hooks
export const useAreasRedux = <T extends object>({ isEditing, initialValues }: AreasReduxProps = {}) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ areas }) => areas?.updating ?? false);
  const error = useAppSelector(({ areas }) => areas?.error ?? false);
  // Component State
  const loading = !initialValues;

  // Submit Handling
  const onSubmit = useCallback(
    (area: Area) => {
      if (isEditing) {
        dispatch(updateArea(area));
      } else {
        dispatch(createArea(area));
      }
    },
    [dispatch, isEditing]
  );

  // Confirmation Modals
  const showAssignReadersConfirm = useCallback(
    ({ areaReaders, areaId }: AssignReadersConfirmProps) => {
      if (areaReaders && areaId) {
        confirm({
          title: intl.formatMessage({
            id: 'areas.checkableTree.confirm.assign',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'areas.checkableTree.confirm.assignSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => dispatch(assignReaders({ areaId, areaReaders })),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  // Tree Items In Readers
  const treeItems = useMemo(() => getAreaReadersTreeItems(initialValues?.AvailableReaders ?? []), [initialValues]);

  const initialKeysInTreeA = useMemo(
    () =>
      getTreeChildren(treeItems)
        ?.filter((item) =>
          initialValues?.Readers?.filter((r: any) => r.IsOutReader === false)?.some(
            (reader: any) => reader.PacsReaderId === item.id
          )
        )
        .map((item) => item.key) ?? [],
    [treeItems, initialValues]
  );

  const initialKeysInTreeB = useMemo(
    () =>
      getTreeChildren(treeItems)
        ?.filter((item) =>
          initialValues?.Readers?.filter((r: any) => r.IsOutReader === true)?.some(
            (reader: any) => reader.PacsReaderId === item.id
          )
        )
        .map((item) => item.key) ?? [],
    [treeItems, initialValues]
  );

  const AreaReadersManagementForm = useCallback(
    () => (
      <LinkedTreeForm
        dataSourceA={treeItems}
        dataSourceB={treeItems}
        initialKeysA={initialKeysInTreeA}
        initialKeysB={initialKeysInTreeB}
        onSave={({ treeA, treeB }) => {
          if (initialValues && initialValues.Id) {
            const areaReaders = getTreeCheckedChildrenIds(treeItems, treeA).map((id) => ({
              PacsReaderId: id,
              IsOutReader: false,
            }));
            const otherAreaReaders = getTreeCheckedChildrenIds(treeItems, treeB).map((id) => ({
              PacsReaderId: id,
              IsOutReader: true,
            }));

            showAssignReadersConfirm({
              areaReaders: [...areaReaders, ...otherAreaReaders],
              areaId: initialValues.Id,
            });
          }
        }}
      />
    ),
    [treeItems, initialKeysInTreeA, initialKeysInTreeB, initialValues, showAssignReadersConfirm]
  );

  const showSyncConfirm = useCallback(() => {
    confirm({
      title: intl.formatMessage({
        id: 'areas.pacsReaders.confirm.sync',
      }),
      icon: <IdcardOutlined />,
      content: intl.formatMessage({
        id: 'areas.pacsReaders.confirm.syncSub',
      }),
      okText: intl.formatMessage({
        id: 'app.yes',
        defaultMessage: 'Yes',
      }),
      cancelText: intl.formatMessage({
        id: 'app.no',
        defaultMessage: 'No',
      }),
      okType: 'primary',
      onOk: () => dispatch(syncReaders()),
      onCancel: () => null,
    });
  }, [intl, dispatch]);

  // Sync Readers Button
  const SyncButton = useMemo(
    () => (
      <IconButton
        title={<Translated id="areas.pacsReaders.syncButton" />}
        onClick={() => showSyncConfirm()}
        Icon={SyncOutlined}
      />
    ),
    [showSyncConfirm]
  );

  const renderLoading = useCallback(
    () => [
      {
        key: '0',
        label: <Translated id="app.loading" />,
        children: (
          <Row>
            <Col xl={8} lg={12} md={12} sm={12} xs={24}>
              <Skeleton active />
            </Col>
          </Row>
        ),
      },
    ],
    []
  );

  const renderEmpty = useCallback(
    () => [
      {
        key: '0',
        label: <Translated id="areas.pacsReaders.tab" />,
        children: (
          <Row>
            <Col xs={24}>
              <Empty
                description={
                  <span>
                    <Translated id="areas.pacsReaders.empty" />
                  </span>
                }
              />
            </Col>
          </Row>
        ),
      },
    ],
    []
  );

  const renderForm = useCallback(
    () => [
      {
        key: '0',
        label: <Translated id="areas.pacsReaders.tab" />,
        children: (
          <Spinner spinning={updating}>
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <AreaReadersManagementForm />
              </Col>
            </Row>
          </Spinner>
        ),
      },
    ],
    [updating, AreaReadersManagementForm]
  );

  const renderItems = useCallback(() => {
    if (loading) return renderLoading();
    if (!initialValues || isEmpty(initialValues)) return renderEmpty();
    return renderForm();
  }, [loading, initialValues, renderLoading, renderEmpty, renderForm]);

  const RenderReaderForm = useCallback(
    () => (
      <StyledWidget styleName="gx-card-widget">
        <StyledTabs tabBarExtraContent={{ right: SyncButton }} items={renderItems()} />
      </StyledWidget>
    ),
    [SyncButton, renderItems]
  );

  // Components
  const CreateAreaForm = useCallback(
    ({ form }: UseFormProps<T>) => (
      <StyledForm
        form={form}
        layout="vertical"
        onFinish={(values) => onSubmit(values as Area)}
        initialValues={initialValues}
      >
        <Spinner spinning={updating}>
          <Form.Item name="Id" hidden>
            <Input />
          </Form.Item>
          <Row>
            <Col>
              <Form.Item
                name="Name"
                label={<Translated id="areas.name" />}
                rules={[{ required: true, message: intl.formatMessage({ id: 'areas.form.warnings.name' }) }]}
              >
                <Input placeholder="Area Name" />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                name="AreaType"
                label={<Translated id="areas.type" />}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'areas.form.warnings.type' }),
                  },
                ]}
              >
                <Select placeholder={<Translated id="areas.type" />}>
                  {AreaTypes.map((type) => (
                    <Option key={type.Value} value={type.Value}>
                      {type.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col>
              <Form.Item name="Capacity" label={<Translated id="areas.capacity" />}>
                <InputNumber min={0} step={1} style={{ width: '97%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Spinner>
      </StyledForm>
    ),
    [onSubmit, updating, initialValues, intl]
  );

  // Form options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'Areas',
        Form: CreateAreaForm,
        labels: {
          createButton: <Translated id="areas.form.create" />,
          drawerForm: <Translated id={isEditing ? 'areas.form.edit' : 'areas.form.create'} />,
          submitButton: <Translated id={isEditing ? 'form.editButton' : 'form.createButton'} />,
        },
      } as FormOptions<T>),
    [CreateAreaForm, isEditing]
  );

  // Confirmation Modals
  const showDeleteConfirm = useCallback(
    (area: Area | null) => {
      if (area) {
        confirm({
          title: intl.formatMessage({
            id: 'areas.confirm.delete',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'areas.confirm.deleteSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteArea(area)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  const showDeleteAllConfirm = useCallback(
    <TRowObject extends object>(selectedFlatRows: Array<TableRow<TRowObject>>) => {
      const areas = selectedFlatRows.map((d) => d.original) as Area[];
      if (areas) {
        confirm({
          title: intl.formatMessage({
            id: 'areas.confirm.deleteAll',
          }),
          icon: <UserDeleteOutlined />,
          content: intl.formatMessage({
            id: 'areas.confirm.deleteAllSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'danger',
          onOk: () => dispatch(deleteAreas(areas)),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  return useMemo(
    () => ({
      formOptions,
      updating,
      error,
      showDeleteConfirm,
      showDeleteAllConfirm,
      RenderReaderForm,
    }),
    [formOptions, updating, error, showDeleteConfirm, showDeleteAllConfirm, RenderReaderForm]
  );
};
