import { PACSIdentifierType } from './PACSIdentifierType';
import { Tenant } from './Tenant';

export interface IdentifierType {
  Id: string;
  Name: string;
  ValueLabel: string;
  DisplayLabel: string;
  DefaultForCheckIn: boolean;
  ShownInSelfRegistration: boolean;
  AllowedToChangeInInvitation: boolean;
  ShowInParkingReservation: boolean;
  RequiredInSelfRegistration: boolean;
  RequiredInInvitation: boolean;
  RequiredInParkingReservation: boolean;
  Tenants: Array<Tenant>;
  HasValidity: boolean;
  OverrideValidity: boolean;
  Validity: string;
  ValidityType: string;
}

export interface IdentifierTypeFormValues {
  Id: string | undefined;
  Name: string | undefined;
  ValueLabel: string | undefined;
  DisplayLabel: string | undefined;
  DefaultForCheckIn: boolean | undefined;
  ShownInSelfRegistration: boolean | undefined;
  AllowedToChangeInInvitation: boolean | undefined;
  ShowInParkingReservation: boolean | undefined;
  RequiredInSelfRegistration: boolean | undefined;
  RequiredInInvitation: boolean | undefined;
  RequiredInParkingReservation: boolean | undefined;
  Tenants: Array<Tenant> | undefined;
}

export interface IdentifierTypeResponse {
  IdentifierType: IdentifierType;
  PacsIdentifierTypes: Array<PACSIdentifierType>;
  AvailablePacsIdentifierTypes: Array<PACSIdentifierType>;
}

export interface IdentifierTypeAdvanceSettingsFormValues {
  Id: string | undefined;
  HasValidity: boolean | undefined;
  OverrideValidity: boolean | undefined;
  Validity: string | undefined;
  ValidityType: string | undefined;
}

export enum TimeFrameUnit {
  Day = 0,
  Week = 1,
  Month = 2,
  Year = 3,
}
