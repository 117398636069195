import { Form, Input } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useAppDispatch } from '../../hooks/App/useRedux';

import { Translated } from '../UI/Core';
import { Widget } from '../UI/Widget/Widget';
import { Spinner } from '../UI/Spinner/Spinner';
import { Area, AreaFormValues } from '../../models/Area';
import { AreaTypes } from '../../models/enums/AreaTypes';
import { updateArea } from '../../store/Areas/Areas.redux';

const { Item } = Form;

interface ContactCardProps {
  area: Area | null;
  pending: boolean;
}

export const ContactCard = ({ area, pending }: ContactCardProps) => {
  // Hooks
  const dispatch = useAppDispatch();

  // Form Values
  const initialValues: AreaFormValues = useMemo(
    () => ({
      Id: area?.Id,
      Name: area?.Name,
      AreaType: area?.AreaType,
      Capacity: area?.Capacity,
      Tenants: [],
    }),
    [area]
  );

  const SettingsForm = useCallback(
    () => (
      <Form layout="vertical" initialValues={initialValues} onFinish={(values: Area) => dispatch(updateArea(values))}>
        {/* Hidden Values */}
        <Item name="Id" hidden>
          <Input />
        </Item>
        <Item name="Name" hidden>
          <Input />
        </Item>
        <Item name="AreaType" hidden>
          <Input />
        </Item>
        <Item name="Capacity" hidden>
          <Input />
        </Item>

        {/* Name */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="areas.name" />
            </span>
            <div className="gx-mb-0">{initialValues?.Name}</div>
          </div>
        </div>

        {/* AreaType */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="areas.type" />
            </span>
            <div className="gx-mb-0">
              {AreaTypes.find((type) => type.Value === initialValues?.AreaType)?.Name || 'Unknown'}
            </div>
          </div>
        </div>

        {/* Capacity */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="areas.capacity" />
            </span>
            <div className="gx-mb-0">{initialValues?.Capacity}</div>
          </div>
        </div>
      </Form>
    ),
    [initialValues, dispatch]
  );

  return (
    <Widget title={<Translated id="app.area" />} styleName="gx-card-profile-sm">
      <Spinner spinning={!!pending}>
        <SettingsForm />
      </Spinner>
    </Widget>
  );
};
