import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../../services/ApiService/ApiService';
import { notificationService } from '../../../services/Notifications/NotificationService';

// Models
import { SignalRAction } from '../../../models/SignalRAction';

// Redux
import { areaTenantsAssigned, areaTenantsNotAssigned, assignAreaTenants } from './AreaTenants.redux';
import { Tenant } from '../../../models/Tenant';

// **************************************************
// ********************* ASSIGN *********************

// Worker Sagas
function* assignAreaTenantsSaga() {
  yield takeEvery(assignAreaTenants.type, assignAreaTenantsRequest);
}

function* areaTenantsAssignedSaga() {
  yield takeLatest(areaTenantsAssigned.type, assignAreaTenantsResponse);
}

function* areaTenantsNotAssignedSaga() {
  yield takeLatest(areaTenantsNotAssigned.type, assignAreaTenantsError);
}

function* assignAreaTenantsRequest(actionsData: PayloadAction<{ areaId: string; tenants: Array<Tenant> }>) {
  try {
    const {
      payload: { areaId, tenants },
    } = actionsData;
    yield apiService.execute({
      url: `Areas/${areaId}/Tenants`,
      method: ApiRequestType.PUT,
      data: {
        AreaId: areaId,
        Tenants: tenants,
      },
    });
  } catch ({ message }) {
    yield put({ type: areaTenantsNotAssigned.type, payload: { msg: { message } } });
  }
}

// Response
function assignAreaTenantsResponse() {
  notificationService.showSuccess('areas.Tenants.notifications.assign');
}

// Error
function assignAreaTenantsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('areas.Tenants.notifications.assignFailed', action?.payload?.msg.message);
}

// **************************************************

// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Assign
    assignAreaTenantsSaga(),
    areaTenantsAssignedSaga(),
    areaTenantsNotAssignedSaga(),
  ]);
}
