import React, { useMemo } from 'react';

// Hooks
import { useAzureGroupsRedux } from './useAzureGroupsRedux';

// Data
import { AzureTenant } from '../../../models/AzureTenant';

// Props
interface AzureGroupTenantsProps {
  azureTenantResponse: AzureTenant | undefined;
  groupIds: string[];
  setGroupIds: React.Dispatch<React.SetStateAction<string[]>>;
}

export const useAzureGroups = ({ azureTenantResponse, groupIds, setGroupIds }: AzureGroupTenantsProps) => {
  // Redux
  const { AzureGroupsManagementForm, updating, SyncButton } = useAzureGroupsRedux({
    azureTenantResponse,
    groupIds,
    setGroupIds,
  });

  // Component State
  const loading = !azureTenantResponse;

  // Return Hook
  return useMemo(
    () => ({
      AzureGroupsManagementForm,
      loading,
      updating,
      SyncButton,
    }),
    [AzureGroupsManagementForm, SyncButton, loading, updating]
  );
};
