// Types
import { SignalREvent } from '../models/SignalREvent';

// Generic
import { configureSignalREvents as configureGenericActions } from './Generic/Generic.signalr';
// Access Profiles
import { configureSignalREvents as configureAccessProfilesActions } from './AccessProfiles/AccessProfiles.signalr';
import { configureSignalREvents as configurePACSAccessProfilesActions } from './AccessProfiles/PACSAccessProfiles/PACSAccessProfiles.signalr';
import { configureSignalREvents as configureAccessProfileTentantsActions } from './AccessProfiles/AccessProfileTenants/AccessProfileTenants.signalr';
// Identifier Types
import { configureSignalREvents as configureIdentifierTypesActions } from './IdentifierTypes/IdentifierTypes.signalr';
import { configureSignalREvents as configurePACSIdentifierTypesActions } from './IdentifierTypes/PACSIdentifierTypes/PACSIdentifierTypes.signalr';
import { configureSignalREvents as configureIdentifierTypeTenantsActions } from './IdentifierTypes/IdentifierTypeTenants/IdentifierTypeTenants.signalr';
// Schedule Configurations
import { configureSignalREvents as configureScheduleConfigurationsActions } from './ScheduleConfigurations/ScheduleConfigurations.signalr';
// Service Configurations
import { configureSignalREvents as configureServiceConfigurationsActions } from './ServiceConfigurations/ServiceConfigurations.signalr';
import { configureSignalREvents as configurePACSSettingsActions } from './ServiceConfigurations/PACSSettings/PACSSettings.signalr';
import { configureSignalREvents as configureServiceConfigurationTenantsActions } from './ServiceConfigurations/ServiceConfigurationTenants/ServiceConfigurationTenants.signalr';
// Api Configurations
import { configureSignalREvents as configureApiConfigurationsActions } from './ApiConfigurations/ApiConfigurations.signalr';
import { configureSignalREvents as configureApiConfigurationTenantsActions } from './ApiConfigurations/ApiConfigurationTenants/ApiConfigurationTenants.signalr';
// Kiosks
import { configureSignalREvents as configureKiosksActions } from './Kiosks/Kiosks.signalr';
// Identity Users
import { configureSignalREvents as configureIdentityUsersActions } from './IdentityUsers/IdentityUsers.signalr';
import { configureSignalREvents as configureIdentityUserTenantsActions } from './IdentityUsers/IdentityUserTenants/IdentityUserTenants.signalr';
import { configureSignalREvents as configureIdentityUserRolesActions } from './IdentityUsers/IdentityUserRoles/IdentityUserRoles.signalr';
// Companies
import { configureSignalREvents as configureCompaniesActions } from './Companies/Companies.signalr';
// Locations
import { configureSignalREvents as configureLocationsActions } from './Locations/Locations.signalr';
// Tenants
import { configureSignalREvents as configureTenantsActions } from './Tenants/Tenants.signalr';
// Clients
import { configureSignalREvents as configureApiClientsActions } from './ApiClients/ApiClients.signalr';
// AzureTenant
import { configureSignalREvents as configureAzureTenantsActions } from './AzureTenants/AzureTenants.signalr';
import { configureSignalREvents as configureAzureGroupsActions } from './AzureTenants/AzureGroups/AzureGroups.signalr';
// Calendar Event Configuration
import { configureSignalREvents as configureCalendarEventConfigurationsActions } from './CalendarEventConfigurations/CalendarEventConfigurations.signalr';
// Actions
import { configureSignalREvents as configureActionActions } from './Actions/Actions.signalr';
// Actions
import { configureSignalREvents as businessClientsActions } from './BusinessClients/BusinessClients.signalr';
// Areas
import { configureSignalREvents as areasActions } from './Areas/Areas.signalr';
import { configureSignalREvents as areaTenantsActions } from './Areas/AreaTenants/AreaTenants.signalr';

// Endpoint Configuration
export const configureReduxEvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Generic
  configureGenericActions({ hubConnection, store, history });

  // Access Profiles
  configureAccessProfilesActions({ hubConnection, store, history });
  configurePACSAccessProfilesActions({ hubConnection, store, history });
  configureAccessProfileTentantsActions({ hubConnection, store, history });

  // Identifier Types
  configureIdentifierTypesActions({ hubConnection, store, history });
  configurePACSIdentifierTypesActions({ hubConnection, store, history });
  configureIdentifierTypeTenantsActions({ hubConnection, store, history });

  // Schedule Configurations
  configureScheduleConfigurationsActions({ hubConnection, store, history });

  // Service Configurations
  configureServiceConfigurationsActions({ hubConnection, store, history });
  configurePACSSettingsActions({ hubConnection, store, history });
  configureServiceConfigurationTenantsActions({ hubConnection, store, history });

  // Api Configurations
  configureApiConfigurationsActions({ hubConnection, store, history });
  configureApiConfigurationTenantsActions({ hubConnection, store, history });

  // Kiosks
  configureKiosksActions({ hubConnection, store, history });

  // Identity Users
  configureIdentityUsersActions({ hubConnection, store, history });
  configureIdentityUserTenantsActions({ hubConnection, store, history });
  configureIdentityUserRolesActions({ hubConnection, store, history });

  // Companies
  configureCompaniesActions({ hubConnection, store, history });

  // Locations
  configureLocationsActions({ hubConnection, store, history });

  // Tenants
  configureTenantsActions({ hubConnection, store, history });

  // API Clients
  configureApiClientsActions({ hubConnection, store, history });

  // Azure Tenant
  configureAzureTenantsActions({ hubConnection, store, history });
  configureAzureGroupsActions({ hubConnection, store, history });

  // Calendar Event Configuration
  configureCalendarEventConfigurationsActions({ hubConnection, store, history });

  // Actions
  configureActionActions({ hubConnection, store, history });

  // Business Clients
  businessClientsActions({ hubConnection, store, history });

  // Areas
  areasActions({ hubConnection, store, history });
  areaTenantsActions({ hubConnection, store, history });
};
