import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Models
import { IdentifierTypeResponse } from '../../../models/IdentifierType';

// Hooks
import { useIdentifierTypeTenantsRedux } from './useIdentifierTypeTenantsRedux';
import { useData } from '../../App/useData';

// Data
import { ApiEndpoints } from '../../../data/ApiEndpoints';
import { useIdentifierTypeAdvancedSettingsRedux } from '../IdentifierTypeAdvancedSettings/useIdentifierTypeAdvancedSettingsRedux';

// Props
interface IdentifierTypeTenantsProps {
  identifierTypeResponse: IdentifierTypeResponse | null;
  fetchIdentifierType: (params: any) => Promise<void>;
  updatingIdentifierType: boolean;
}

export const useIdentifierTypeSettings = ({
  identifierTypeResponse: identifierTypeTenantsResponse,
  fetchIdentifierType,
  updatingIdentifierType: updatingIdentifierTypeTenant,
}: IdentifierTypeTenantsProps) => {
  // Data
  const {
    data: identifierTypeTenants,
    fetch: fetchIdentifierTypeTenants,
    pending,
  } = useData(ApiEndpoints.tenants.list, null);

  // Redux
  const { IdentifierTypeTenantsManagementForm, updating, error } = useIdentifierTypeTenantsRedux({
    identifierTypeTenantsResponse,
    identifierTypeTenants,
  });

  const {
    IdentifierTypeAdvancedSettingsManagementForm,
    updating: validityUpdating,
    error: validityError,
  } = useIdentifierTypeAdvancedSettingsRedux({
    identifierTypeTenantsResponse,
  });

  const prevUpdating = usePrevious(updating);
  const prevValidityUpdating = usePrevious(validityUpdating);

  // Component State
  const loading = pending && !identifierTypeTenantsResponse;

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetchIdentifierTypeTenants();
  }, [fetchIdentifierTypeTenants]);

  useEffect(() => {
    // Fetch after updating
    if (
      (identifierTypeTenantsResponse && prevUpdating === true && !updating && !error) ||
      (identifierTypeTenantsResponse && prevValidityUpdating === true && !validityUpdating && !validityError)
    ) {
      fetchIdentifierType({ id: identifierTypeTenantsResponse?.IdentifierType.Id });
      fetchIdentifierTypeTenants();
    }
  }, [
    prevUpdating,
    updating,
    error,
    fetchIdentifierType,
    fetchIdentifierTypeTenants,
    identifierTypeTenantsResponse,
    prevValidityUpdating,
    validityUpdating,
    validityError,
  ]);

  // Return Hook
  return useMemo(
    () => ({
      identifierTypeTenants,
      IdentifierTypeTenantsManagementForm,
      IdentifierTypeAdvancedSettingsManagementForm,
      loading,
      updating: updating || updatingIdentifierTypeTenant || validityUpdating,
    }),
    [
      identifierTypeTenants,
      IdentifierTypeTenantsManagementForm,
      IdentifierTypeAdvancedSettingsManagementForm,
      loading,
      updating,
      updatingIdentifierTypeTenant,
      validityUpdating,
    ]
  );
};
