export const LocalStorageKeys = {
  AppSettings: {
    locale: 'AppSettings.locale',
    lastChecked: 'AppSettings.lastChecked',
  },
  IdentityUsers: {
    table: {
      hiddenColumns: 'IdentityUsers.table.hiddenColumns',
    },
  },
  Clients: {
    table: {
      hiddenColumns: 'Clients.table.hiddenColumns',
    },
  },
  Companies: {
    table: {
      hiddenColumns: 'Companies.table.hiddenColumns',
    },
  },
  Locations: {
    table: {
      hiddenColumns: 'Locations.table.hiddenColumns',
    },
  },
  Tenants: {
    table: {
      hiddenColumns: 'Tenants.table.hiddenColumns',
    },
  },
  IdentifierTypes: {
    table: {
      hiddenColumns: 'IdentifierTypes.table.hiddenColumns',
    },
  },
  Kiosks: {
    table: {
      hiddenColumns: 'Kiosks.table.hiddenColumns',
    },
  },
  AccessProfiles: {
    table: {
      hiddenColumns: 'AccessProfiles.table.hiddenColumns',
    },
  },
  ServiceConfigurations: {
    table: {
      hiddenColumns: 'ServiceConfigurations.table.hiddenColumns',
    },
  },
  ScheduleConfigurations: {
    table: {
      hiddenColumns: 'ScheduleConfigurations.table.hiddenColumns',
    },
  },
  ApiConfigurations: {
    table: {
      hiddenColumns: 'ApiConfigurations.table.hiddenColumns',
    },
  },
  AzureTenants: {
    table: {
      hiddenColumns: 'AzureTenants.table.hiddenColumns',
    },
  },
  CalendarEventConfigurations: {
    table: {
      hiddenColumns: 'CalendarEventConfigurations.table.hiddenColumns',
    },
  },
  Actions: {
    table: {
      hiddenColumns: 'Actions.table.hiddenColumns',
    },
  },
  BusinessClients: {
    table: {
      hiddenColumns: 'BusinessClients.table.hiddenColumns',
    },
  },
  Areas: {
    table: {
      hiddenColumns: 'Areas.table.hiddenColumns',
    },
  },
};

export const getFromLocalStorage = <T extends object>(key: string, initialValue?: T) => {
  try {
    // Gets value from LocalStorage
    const value = localStorage.getItem(key);
    // Checks if the key/value pair exists
    if (value === null) {
      // If an initial value is provided, it is persisted and returned
      if (initialValue) {
        saveToLocalStorage(key, initialValue);
        return initialValue;
      }
      // Returns undefined in case it does not exists and no initial value is provided
      return undefined;
    }
    // Returns the value parsed into the provided type
    return JSON.parse(value) as T;
  } catch {
    // Returns undefined in case of an error
    return undefined;
  }
};

export const saveToLocalStorage = <T extends object>(key: string, value: T) => {
  try {
    // Serializes and stores the provided value
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch {
    // Error
  }
};
