/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { SignalRAction } from '../../../models/SignalRAction';
import { AzureTenant } from '../../../models/AzureTenant';
import { AzureGroup } from '../../../models/AzureGroup';

// State
interface AzureGroupsState {
  updating: boolean;
  error: boolean;
}

const initialState: AzureGroupsState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@AZURE_GROUPS';

// Redux Actions|Reducers
const azureGroupsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Assign
    assignAzureGroups: (state, action: PayloadAction<{ azureTenant: AzureTenant; azureGroups: AzureGroup[] }>) => {
      state.updating = true;
      state.error = false;
    },
    azureGroupsAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    azureGroupsNotAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Sync
    syncAzureGroups: (state, action: PayloadAction<{ azureTenant: AzureTenant }>) => {
      state.updating = true;
      state.error = false;
    },
    azureGroupsSynced: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    azureGroupsNotSynced: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Assign Azure Groups Access Profiles
    assignAzureGroupsAccessProfiles: (
      state,
      action: PayloadAction<{ accessProfileId: any; azureGroups: AzureGroup[] }>
    ) => {
      state.updating = true;
      state.error = false;
    },
    azureGroupsAccessProfilesAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    azureGroupsAccessProfilesNotAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Assign
  assignAzureGroups,
  azureGroupsAssigned,
  azureGroupsNotAssigned,
  // Sync
  syncAzureGroups,
  azureGroupsSynced,
  azureGroupsNotSynced,
  // Assign Azure Groups Access Profiles
  assignAzureGroupsAccessProfiles,
  azureGroupsAccessProfilesAssigned,
  azureGroupsAccessProfilesNotAssigned,
} = azureGroupsSlice.actions;

// Export Reducer
export default azureGroupsSlice.reducer;
