import React from 'react';
import { Col, Row } from 'antd';

// Hooks
import { useParams } from 'react-router';
import { useIdentifierTypesDetail } from '../../hooks/IdentifierTypes/useIdentifierTypeDetail';

// Components
import { Container } from '../../components/UI/Base';
import {
  ContactCard,
  IdentifierTypeSettings,
  PACSIdentifierTypes,
  ProfileHeader,
} from '../../components/IdentifierTypes';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { FormDrawer } from '../../components/UI/Drawer/FormDrawer';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';

export const IdentifierTypeDetailPage = () => {
  const { id } = useParams();
  const {
    updating,
    getBreadcrumbMenuProps,
    getProfileHeaderProps,
    getContactCardProps,
    getFormDrawerProps,
    getIdentifierTypeTenantsProps,
    getPACSIdentifierTypesProps,
  } = useIdentifierTypesDetail({
    id,
  });

  return (
    <Spinner spinning={updating}>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <ProfileHeader {...getProfileHeaderProps()} />

      <Container className="gx-profile-content">
        <Row>
          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <IdentifierTypeSettings {...getIdentifierTypeTenantsProps()} />
          </Col>

          <Col xl={8} lg={10} md={10} sm={24} xs={24}>
            <ContactCard {...getContactCardProps()} />
          </Col>

          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <PACSIdentifierTypes {...getPACSIdentifierTypesProps()} />
          </Col>
        </Row>
      </Container>

      <FormDrawer {...getFormDrawerProps()} />
    </Spinner>
  );
};
