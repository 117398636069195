import React, { useCallback, useMemo } from 'react';
import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';

// Models
import styled from 'styled-components';

// Redux
import { useAppDispatch, useAppSelector } from '../../App/useRedux';

// Components
import { Translated } from '../../../components/UI/Core';
import {
  IdentifierType,
  IdentifierTypeAdvanceSettingsFormValues,
  IdentifierTypeResponse,
  TimeFrameUnit,
} from '../../../models/IdentifierType';
import { updateIdentifierTypeAdvanceSettings } from '../../../store/IdentifierTypes/IdentifierTypes.redux';

const { Item } = Form;

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;
const NoMarginBottomFormItem = styled(NoSidePaddingFormItem)`
  margin-bottom: 0;
`;
const StyledForm = styled(Form)`
  flex-direction: row;
`;

interface IdentifierTypeFormProps {
  identifierTypeTenantsResponse: IdentifierTypeResponse | null;
}

// Hook

export const useIdentifierTypeAdvancedSettingsRedux = ({ identifierTypeTenantsResponse }: IdentifierTypeFormProps) => {
  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ identifierTypes }) => identifierTypes?.updating ?? false);
  const error = useAppSelector(({ identifierTypes }) => identifierTypes?.error ?? false);
  const [form] = Form.useForm();
  // Form Values
  const initialValues: IdentifierTypeAdvanceSettingsFormValues = useMemo(
    () => ({
      Id: identifierTypeTenantsResponse?.IdentifierType?.Id,
      HasValidity: identifierTypeTenantsResponse?.IdentifierType?.HasValidity,
      OverrideValidity: identifierTypeTenantsResponse?.IdentifierType?.OverrideValidity,
      Validity: identifierTypeTenantsResponse?.IdentifierType?.Validity,
      ValidityType: identifierTypeTenantsResponse?.IdentifierType?.ValidityType,
    }),
    [identifierTypeTenantsResponse]
  );
  const hasValidity = Form.useWatch('HasValidity', form);

  const onSubmit = useCallback(
    (values: IdentifierType) => {
      dispatch(updateIdentifierTypeAdvanceSettings(values));
    },
    [dispatch]
  );

  // Components
  const IdentifierTypeAdvancedSettingsManagementForm = useCallback(() => {
    const handleFinish = (values: any) => {
      onSubmit(values);
    };
    return (
      <StyledForm form={form} onFinish={handleFinish} initialValues={initialValues}>
        <Item name="Id" hidden>
          <Input />
        </Item>
        <Row>
          <Col span={4} className="custom-class">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="identifierTypes.enable.validity" />
            </span>
          </Col>
          <Col span={12}>
            <NoMarginBottomFormItem name="HasValidity" valuePropName="checked">
              <Switch checkedChildren={<Translated id="app.yes" />} unCheckedChildren={<Translated id="app.no" />} />
            </NoMarginBottomFormItem>
          </Col>
        </Row>
        {hasValidity && (
          <>
            <Row>
              <Col span={4} className="custom-class">
                <span className="gx-mb-0 gx-text-grey gx-fs-sm">
                  <Translated id="identifierTypes.allow.override" />
                </span>
              </Col>
              <Col span={12}>
                <NoMarginBottomFormItem name="OverrideValidity" valuePropName="checked">
                  <Switch
                    checkedChildren={<Translated id="app.yes" />}
                    unCheckedChildren={<Translated id="app.no" />}
                  />
                </NoMarginBottomFormItem>
              </Col>
            </Row>
            <Row>
              <Col span={4} className="custom-class">
                <span className="gx-mb-0 gx-text-grey gx-fs-sm">
                  <Translated id="identifierTypes.timeframe" />
                </span>
              </Col>
              <Col span={3}>
                <Form.Item name="Validity" rules={[{ required: true, message: 'Please input quantity!' }]}>
                  <Input placeholder="Quantity" type="number" style={{ width: '100px' }} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="ValidityType" rules={[{ required: true, message: 'Please select a type!' }]}>
                  <Select placeholder="Select Type" style={{ width: '150px' }}>
                    <Select.Option value={TimeFrameUnit.Day}>
                      {' '}
                      <Translated id="identifier.timeFrameUnit.day" />
                    </Select.Option>
                    <Select.Option value={TimeFrameUnit.Week}>
                      {' '}
                      <Translated id="identifier.timeFrameUnit.week" />
                    </Select.Option>
                    <Select.Option value={TimeFrameUnit.Month}>
                      {' '}
                      <Translated id="identifier.timeFrameUnit.month" />
                    </Select.Option>
                    <Select.Option value={TimeFrameUnit.Year}>
                      {' '}
                      <Translated id="identifier.timeFrameUnit.year" />
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        <StyledButton type="primary" htmlType="submit">
          <Translated id="form.confirmButton" />
        </StyledButton>
      </StyledForm>
    );
  }, [form, hasValidity, initialValues, onSubmit]);

  return useMemo(
    () => ({
      IdentifierTypeAdvancedSettingsManagementForm,
      updating,
      error,
    }),
    [IdentifierTypeAdvancedSettingsManagementForm, error, updating]
  );
};
