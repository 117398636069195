import React, { useCallback, useMemo } from 'react';
import { Button, Divider, Form, Modal } from 'antd';
import { Store } from 'antd/lib/form/interface';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { IdcardOutlined } from '@ant-design/icons';

// Models
import { ScheduleSettingsFormValues } from '../../../models/ScheduleSettingFormValues';
import { ScheduleConfigurationResponse } from '../../../models/ScheduleConfiguration';
import { ScheduleConfigurationJobType } from '../../../models/enums/ScheduleConfigurationTypes';

// Redux
import { useAppDispatch, useAppSelector } from '../../App/useRedux';
import { updateScheduleConfiguration } from '../../../store/ScheduleConfigurations/ScheduleConfigurations.redux';

// Hooks
import { useFetchInvitationEmailsSettings } from './FetchInvitationEmails/useFetchInvitationEmailsSettings';
import { useImportAzureAdUsersSettings } from './ImportAzureAdUsers/useImportAzureAdUsersSettings';
import { useCheckOutVisitorsSettings } from './CheckOutVisitors/useCheckOutVisitorsSettings';
import { useRemoveRestrictedAccessProfilesSettings } from './RemoveRestrictedAccessProfiles/useRemoveRestrictedAccessProfilesSettings';

// Components
import { Translated } from '../../../components/UI/Core';
import { useDisableExpiredIdentifiersSettings } from './DisableExpiredIdentifiers/useDisableExpiredIdentifiersSettings';

const { Item } = Form;
const { confirm } = Modal;

// Styled
const NoBottomMarginFormItem = styled(Item)`
  margin-bottom: 0;
`;
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Props
interface ScheduleSettingsReduxProps {
  scheduleConfigurationResponse: ScheduleConfigurationResponse | null;
}

interface FormSettingsProps {
  SettingsForm: () => JSX.Element;
  initialValues?: Store;
}

interface AssignConfirmProps {
  scheduleSettingsFormValues: ScheduleSettingsFormValues;
}

// Hook
export const useScheduleSettingsRedux = ({ scheduleConfigurationResponse }: ScheduleSettingsReduxProps) => {
  // Intl
  const intl = useIntl();
  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ scheduleConfigurations }) => scheduleConfigurations?.updating ?? false);
  const error = useAppSelector(({ scheduleConfigurations }) => scheduleConfigurations?.error ?? false);

  // Confirmation Modals
  const showAssignConfirm = useCallback(
    ({ scheduleSettingsFormValues }: AssignConfirmProps) => {
      if (scheduleSettingsFormValues) {
        confirm({
          title: intl.formatMessage({
            id: 'scheduleConfigurations.scheduleSettings.confirm.sync',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'scheduleConfigurations.scheduleSettings.confirm.syncSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => dispatch(updateScheduleConfiguration(scheduleSettingsFormValues)),
          onCancel: () => null,
        });
      }
    },
    [dispatch, intl]
  );

  // Schedule Hook Forms
  const { FetchInvitationEmailsSettingsForm, initialValues: fetchInvitationEmailsInitialValues } =
    useFetchInvitationEmailsSettings({
      scheduleConfigurationResponse,
    });
  const { ImportAzureAdSettingsForm, initialValues: importAzureAdInitialValues } = useImportAzureAdUsersSettings({
    scheduleConfigurationResponse,
  });
  const { CheckOutVisitorsSettingsForm, initialValues: checkOutVisitorsInitialValues } = useCheckOutVisitorsSettings({
    scheduleConfigurationResponse,
  });
  const { RemoveRestrictedAccessProfilesSettingsForm, initialValues: removeRestrictedAccessProfilesInitialValues } =
    useRemoveRestrictedAccessProfilesSettings({
      scheduleConfigurationResponse,
    });
  const { DisableExpiredIdentifiersSettingsForm, initialValues: disableExpiredIdentifiersInitialValues } =
    useDisableExpiredIdentifiersSettings({
      scheduleConfigurationResponse,
    });

  let settings: FormSettingsProps;
  switch (scheduleConfigurationResponse?.ScheduleConfigurationType) {
    case ScheduleConfigurationJobType.FetchInvitationEmails:
      settings = {
        SettingsForm: FetchInvitationEmailsSettingsForm,
        initialValues: fetchInvitationEmailsInitialValues,
      };
      break;
    case ScheduleConfigurationJobType.ImportAzureAdUsers:
      settings = {
        SettingsForm: ImportAzureAdSettingsForm,
        initialValues: importAzureAdInitialValues,
      };
      break;
    case ScheduleConfigurationJobType.CheckOutVisitors:
      settings = {
        SettingsForm: CheckOutVisitorsSettingsForm,
        initialValues: checkOutVisitorsInitialValues,
      };
      break;
    case ScheduleConfigurationJobType.RemoveRestrictedAccessProfiles:
      settings = {
        SettingsForm: RemoveRestrictedAccessProfilesSettingsForm,
        initialValues: removeRestrictedAccessProfilesInitialValues,
      };
      break;
    case ScheduleConfigurationJobType.DisableExpiredIdentifiers:
      settings = {
        SettingsForm: DisableExpiredIdentifiersSettingsForm,
        initialValues: disableExpiredIdentifiersInitialValues,
      };
      break;
    default:
      settings = {
        SettingsForm: () => <div />,
        initialValues: {},
      };
      break;
  }
  const { initialValues, SettingsForm } = settings;

  // Components
  const ScheduleSettingManagementForm = useCallback(
    () => (
      <Form
        layout="vertical"
        initialValues={initialValues}
        onFinish={(values: ScheduleSettingsFormValues) => {
          return (
            scheduleConfigurationResponse &&
            showAssignConfirm({
              scheduleSettingsFormValues: {
                ...values,
                CustomConfiguration: JSON.stringify(values.CustomConfiguration ?? {}),
              },
            })
          );
        }}
      >
        <SettingsForm />

        <Divider />

        {/* Action Buttons */}
        <NoBottomMarginFormItem>
          <StyledButton type="primary" htmlType="submit">
            <Translated id="form.confirmButton" />
          </StyledButton>
        </NoBottomMarginFormItem>
      </Form>
    ),
    [initialValues, SettingsForm, scheduleConfigurationResponse, showAssignConfirm]
  );

  return useMemo(
    () => ({
      ScheduleSettingManagementForm,
      updating,
      error,
    }),
    [ScheduleSettingManagementForm, updating, error]
  );
};
