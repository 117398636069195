import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { Area, AreaReader } from '../../models/Area';
import { SignalRAction, SignalRCrudAction } from '../../models/SignalRAction';

// Redux
import {
  areaCreated,
  areaDeleted,
  areaNotCreated,
  areaNotDeleted,
  areaNotUpdated,
  areaUpdated,
  createArea,
  deleteArea,
  deleteAreas,
  updateArea,
  assignReaders,
  readersAssigned,
  readersNotAssigned,
  readersNotSynced,
  readersSynced,
  syncReaders,
} from './Areas.redux';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createAreaSaga() {
  yield takeEvery(createArea.type, createAreaRequest);
}

function* areaCreatedSaga() {
  yield takeLatest(areaCreated.type, createAreaResponse);
}

function* areaNotCreatedSaga() {
  yield takeLatest(areaNotCreated.type, createAreaError);
}

// Request
function* createAreaRequest(action: PayloadAction<Area>) {
  try {
    const { payload: area } = action;
    yield apiService.execute({
      url: 'Areas',
      method: ApiRequestType.POST,
      data: area,
    });
  } catch ({ message }) {
    yield put({ type: areaNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createAreaResponse(action: PayloadAction<SignalRCrudAction>) {
  notificationService.showSuccess('areas.notifications.create');
  action.payload.history.push(`/Areas/${action.payload.msg.affectedEntities[0]}`);
}

// Error
function createAreaError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('areas.notifications.createFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPDATE *********************

// Worker Sagas
function* updateAreaSaga() {
  yield takeEvery(updateArea.type, updateAreaRequest);
}

function* areaUpdatedSaga() {
  yield takeLatest(areaUpdated.type, updateAreaResponse);
}

function* areaNotUpdatedSaga() {
  yield takeLatest(areaNotUpdated.type, updateAreaError);
}

// Request
function* updateAreaRequest(action: PayloadAction<Area>) {
  try {
    const { payload: areas } = action;
    yield apiService.execute({
      url: `areas/${areas.Id}`,
      method: ApiRequestType.PUT,
      data: areas,
    });
  } catch ({ message }) {
    yield put({ type: areaNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateAreaResponse() {
  notificationService.showSuccess('areas.notifications.update');
}

// Error
function updateAreaError(action: PayloadAction<SignalRCrudAction>) {
  notificationService.showErrorWithContent('areas.notifications.updateFailed', action?.payload?.msg.message ?? '');
}

// **************************************************
// ********************* DELETE *********************

// Worker Sagas
function* deleteAreaSaga() {
  yield takeEvery(deleteArea.type, deleteAreaRequest);
}

function* areaDeletedSaga() {
  yield takeLatest(areaDeleted.type, deleteAreaResponse);
}

function* areaNotDeletedSaga() {
  yield takeLatest(areaNotDeleted.type, deleteAreaError);
}

// Request
function* deleteAreaRequest(action: PayloadAction<Area>) {
  try {
    const { payload: area } = action;
    yield apiService.execute({
      url: `areas/${area.Id}`,
      method: ApiRequestType.DELETE,
      data: area,
    });
  } catch ({ message }) {
    yield put({ type: areaNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteAreaResponse(action: PayloadAction<SignalRCrudAction>) {
  if (action.payload.msg.affectedEntities.length > 1) {
    notificationService.showSuccess('areas.notifications.deleteAll');
  } else {
    notificationService.showSuccess('areas.notifications.delete');
    action.payload.history.push(`/Areas`);
  }
}

// Error
function deleteAreaError(action: PayloadAction<SignalRAction>) {
  if (action.payload.msg.affectedEntities.length > 1) {
    notificationService.showErrorWithContent('areas.notifications.deleteAllFailed', action?.payload?.msg.message);
  } else {
    notificationService.showErrorWithContent('areas.notifications.deleteFailed', action?.payload?.msg.message);
  }
}

// **************************************************
// ********************* DELETE All *****************

// Worker Sagas
function* deleteAreasSaga() {
  yield takeEvery(deleteAreas.type, deleteAreasRequest);
}

// Request
function* deleteAreasRequest(action: PayloadAction<Array<Area>>) {
  try {
    const { payload: areas } = action;
    yield apiService.execute({
      url: `areas`,
      method: ApiRequestType.DELETE,
      data: { ids: areas.map((area) => area.Id) },
    });
  } catch ({ message }) {
    yield put({ type: areaNotDeleted.type, payload: { msg: { message } } });
  }
}

// **************************************************
// ********************* ASSIGN *********************

// Worker Sagas
function* assignReadersSaga() {
  yield takeEvery(assignReaders.type, assignReadersRequest);
}

function* readersAssignedSaga() {
  yield takeLatest(readersAssigned.type, assignReadersResponse);
}

function* readersNotAssignedSaga() {
  yield takeLatest(readersNotAssigned.type, assignReadersError);
}

// Request
function* assignReadersRequest(action: PayloadAction<{ areaId: string; areaReaders: Array<AreaReader> }>) {
  try {
    const {
      payload: { areaId, areaReaders },
    } = action;
    yield apiService.execute({
      url: `Areas/${areaId}/Readers`,
      method: ApiRequestType.PUT,
      data: {
        areaId,
        areaReaders,
      },
    });
  } catch ({ message }) {
    yield put({ type: readersNotAssigned.type, payload: { msg: { message } } });
  }
}

// Response
function assignReadersResponse() {
  notificationService.showSuccess('areas.readers.notifications.assigned');
}

// Error
function assignReadersError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('areas.readers.notifications.assignFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* SYNC ***********************

// Worker Sagas
function* syncReadersSaga() {
  yield takeEvery(syncReaders.type, syncReadersRequest);
}

function* readersSyncedSaga() {
  yield takeLatest(readersSynced.type, syncReadersResponse);
}

function* readersNotSyncedSaga() {
  yield takeLatest(readersNotSynced.type, syncReadersError);
}

// Request
function* syncReadersRequest() {
  try {
    yield apiService.execute({
      url: `Areas/SyncPacsReaders`,
      method: ApiRequestType.POST,
      data: {},
    });
  } catch ({ message }) {
    yield put({ type: readersNotSynced.type, payload: { msg: { message } } });
  }
}

// Response
function syncReadersResponse() {
  notificationService.showSuccess('areas.readers.notifications.synced');
}

// Error
function syncReadersError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('areas.readers.notifications.syncFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createAreaSaga(),
    areaCreatedSaga(),
    areaNotCreatedSaga(),
    // Update
    updateAreaSaga(),
    areaUpdatedSaga(),
    areaNotUpdatedSaga(),
    // Delete
    deleteAreaSaga(),
    areaDeletedSaga(),
    areaNotDeletedSaga(),
    // Delete All
    deleteAreasSaga(),
    // Assign
    assignReadersSaga(),
    readersAssignedSaga(),
    readersNotAssignedSaga(),
    // Sync
    syncReadersSaga(),
    readersSyncedSaga(),
    readersNotSyncedSaga(),
  ]);
}
