// Types
import { SignalREvent } from '../../models/SignalREvent';

// Actions
import {
  areaCreated,
  areaDeleted,
  areaNotCreated,
  areaNotDeleted,
  areaNotUpdated,
  areaUpdated,
  readersSynced,
  readersNotSynced,
  readersAssigned,
  readersNotAssigned,
} from './Areas.redux';

import { OperationType } from '../../models/SignalRAction';

// Events
const SignalREvents = {
  AreaOperation: 'AreaOperationIntegrationEvent',
  ReadersAssigned: `AreaReadersAssignedIntegrationEvent`,
  ReadersSynced: `PacsReadersSyncedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  AreaOperationFailed: 'AreaOperationFailedIntegrationException',
  ReadersNotAssigned: `AreaReadersNotAssignedIntegrationException`,
  ReadersNotSynced: `PacsReadersNotSyncedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // CRUD Operations
  hubConnection.on(SignalREvents.AreaOperation, (msg) => {
    const op = msg.operation as OperationType;
    switch (op) {
      case OperationType.Create:
        store.dispatch(areaCreated({ history, msg }));
        break;
      case OperationType.Update:
        store.dispatch(areaUpdated({ history, msg }));
        break;
      case OperationType.Delete:
        store.dispatch(areaDeleted({ history, msg }));
        break;
      default:
        break;
    }
  });
  hubConnection.on(SignalRExceptions.AreaOperationFailed, (msg) => {
    const op = msg.operation as OperationType;
    switch (op) {
      case OperationType.Create:
        store.dispatch(areaNotCreated({ history, msg }));
        break;
      case OperationType.Update:
        store.dispatch(areaNotUpdated({ history, msg }));
        break;
      case OperationType.Delete:
        store.dispatch(areaNotDeleted({ history, msg }));
        break;
      default:
        break;
    }
  });
  // Assign
  hubConnection.on(SignalREvents.ReadersAssigned, (msg) => {
    store.dispatch(readersAssigned({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ReadersNotAssigned, (msg) => {
    store.dispatch(readersNotAssigned({ history, msg }));
  });

  // Sync
  hubConnection.on(SignalREvents.ReadersSynced, (msg) => {
    store.dispatch(readersSynced({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.ReadersNotSynced, (msg) => {
    store.dispatch(readersNotSynced({ history, msg }));
  });
};
