import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Modal } from 'antd';
import { IdcardOutlined, SyncOutlined } from '@ant-design/icons';

// Models
import { AzureTenant } from '../../../models/AzureTenant';

// Redux
import { useAppDispatch, useAppSelector } from '../../App/useRedux';
import { syncAzureGroups } from '../../../store/AzureTenants/AzureGroups/AzureGroups.redux';

// Components
import { Translated } from '../../../components/UI/Core';
import { ItemTransfer, TransferItem } from '../../../components/UI/Transfer/ItemTransfer';
import { IconButton } from '../../../components/UI/Button/IconButton';

const { confirm } = Modal;

// Props
interface AzureGroupsFormProps {
  azureTenantResponse: AzureTenant | undefined;
  groupIds: string[];
  setGroupIds: React.Dispatch<React.SetStateAction<string[]>>;
}

interface SyncConfirmProps {
  azureTenant: AzureTenant;
}

interface SyncButtonProps {
  azureTenant: AzureTenant | undefined;
}

// Hook
export const useAzureGroupsRedux = ({ azureTenantResponse, groupIds, setGroupIds }: AzureGroupsFormProps) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ azureGroups: azureGroupsState }) => azureGroupsState?.updating ?? false);

  const showSyncConfirm = useCallback(
    ({ azureTenant }: SyncConfirmProps) => {
      confirm({
        title: intl.formatMessage({
          id: 'azureTenants.azureGroups.confirm.sync',
        }),
        icon: <IdcardOutlined />,
        content: intl.formatMessage({
          id: 'azureTenants.azureGroups.confirm.syncSub',
        }),
        okText: intl.formatMessage({
          id: 'app.yes',
          defaultMessage: 'Yes',
        }),
        cancelText: intl.formatMessage({
          id: 'app.no',
          defaultMessage: 'No',
        }),
        okType: 'primary',
        onOk: () => dispatch(syncAzureGroups({ azureTenant })),
        onCancel: () => null,
      });
    },
    [intl, dispatch]
  );

  // Sync PACSIdentifierTypes Button
  const SyncButton = useCallback(
    ({ azureTenant }: SyncButtonProps) => {
      if (!azureTenant) return null;
      return (
        <IconButton
          title={<Translated id="azureTenants.azureGroups.syncButton" />}
          onClick={() => showSyncConfirm({ azureTenant })}
          Icon={SyncOutlined}
        />
      );
    },
    [showSyncConfirm]
  );

  // Components
  const AzureGroupsManagementForm = useCallback(() => {
    const dataSource =
      azureTenantResponse?.Groups?.map<TransferItem>((azureGroup) => ({
        key: azureGroup.Id,
        title: azureGroup.Name,
        description: '',
      })) ?? [];

    return <ItemTransfer dataSource={dataSource} targetKeys={groupIds} setTargetKeys={setGroupIds} />;
  }, [azureTenantResponse?.Groups, groupIds, setGroupIds]);

  return useMemo(
    () => ({
      AzureGroupsManagementForm,
      updating,
      SyncButton,
    }),
    [AzureGroupsManagementForm, updating, SyncButton]
  );
};
