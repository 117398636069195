import React from 'react';
import { Col, Empty, Row, Skeleton, Tabs } from 'antd';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

// Models
import { Area } from '../../models/Area';
// Hooks
import { useAreaTenants } from '../../hooks/Areas/AreaTenants/useAreaTenants';
// Components
import { Widget } from '../UI/Widget/Widget';
import { Spinner } from '../UI/Spinner/Spinner';
import { Translated } from '../UI/Core';

// Styled
const StyledWidget = styled(Widget)`
  & .ant-card-body {
    padding: 0;
  }
`;
const StyledTabs = styled(Tabs)`
  & .ant-tabs-nav-wrap {
    margin-left: 24px;
  }

  & .ant-tabs-nav {
    height: 58px;
  }

  & .ant-tabs-content {
    padding: 0px 24px 16px;
  }
`;

// Props
interface AreaTenantsProps {
  areaResponse: Area | null;
  fetchArea: (params: any) => Promise<void>;
  updatingArea: boolean;
}

// Component
export const AreaTenants = ({ areaResponse, fetchArea, updatingArea }: AreaTenantsProps) => {
  // Identifiers Hook
  const { loading, updating, areaTenants, AreaTenantsManagementForm } = useAreaTenants({
    areaResponse,
    fetchArea,
    updatingArea,
  });

  // Render Methods
  const renderLoading = () => [
    {
      key: '0',
      label: <Translated id="app.loading" />,
      children: (
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      ),
    },
  ];

  const renderEmpty = () => [
    {
      key: '0',
      label: <Translated id="areas.tenants.tab" />,
      children: (
        <Row>
          <Col xs={24}>
            <Empty
              description={
                <span>
                  <Translated id="areas.tenants.empty" />
                </span>
              }
            />
          </Col>
        </Row>
      ),
    },
  ];

  const renderForm = () => [
    {
      key: '0',
      label: <Translated id="areas.tenants.tab" />,
      children: (
        <Spinner spinning={updating}>
          <AreaTenantsManagementForm />
        </Spinner>
      ),
    },
  ];

  const renderItems = () => {
    if (loading) return renderLoading();
    if (!areaTenants || isEmpty(areaTenants)) return renderEmpty();
    return renderForm();
  };

  // Component Render
  return (
    <StyledWidget styleName="gx-card-widget">
      <StyledTabs items={renderItems()} />
    </StyledWidget>
  );
};
