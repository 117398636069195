/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { SignalRAction } from '../../../models/SignalRAction';
import { Tenant } from '../../../models/Tenant';

// State
interface AreaTenantsState {
  updating: boolean;
  error: boolean;
}

const initialState: AreaTenantsState = {
  updating: false,
  error: false,
};

// Name
const storeName = '@AREAS_TENANTS';

// Redux Areas|Reducers
const areaTenantsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Assign tenants
    assignAreaTenants: (state, action: PayloadAction<{ areaId: string; tenants: Array<Tenant> }>) => {
      state.updating = true;
      state.error = false;
    },
    areaTenantsAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    areaTenantsNotAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Assign
  assignAreaTenants,
  areaTenantsAssigned,
  areaTenantsNotAssigned,
} = areaTenantsSlice.actions;

// Export Reducer
export default areaTenantsSlice.reducer;
