import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Card, Tree, Row, Col } from 'antd';
import { TreeItem } from './TreeForm';

// Components
import { Translated } from '../Core';

// Types
interface LinkedTreeFormProps {
  dataSourceA: Array<TreeItem>;
  dataSourceB: Array<TreeItem>;
  initialKeysA: Array<string>;
  initialKeysB: Array<string>;
  onSave: (values: { treeA: Array<string>; treeB: Array<string> }) => void;
}

// Styled
const StyledCard = styled(Card)`
  padding: 12px 4px 8px;
  margin-bottom: 12px;
`;

const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Component
export const LinkedTreeForm = ({
  dataSourceA,
  dataSourceB,
  initialKeysA,
  initialKeysB,
  onSave,
}: LinkedTreeFormProps) => {
  const [checkedKeysA, setCheckedKeysA] = useState<Array<string>>(initialKeysA);
  const [checkedKeysB, setCheckedKeysB] = useState<Array<string>>(initialKeysB);

  const [expandedKeysA, setExpandedKeysA] = useState<Array<React.Key>>(initialKeysA);
  const [expandedKeysB, setExpandedKeysB] = useState<Array<React.Key>>(initialKeysB);

  const applyDisabledKeys = (treeData: Array<TreeItem>, disabledKeys: Array<string>): Array<TreeItem> => {
    return treeData.map((item) => ({
      ...item,
      disabled: disabledKeys.includes(item.key),
      children: item.children ? applyDisabledKeys(item.children, disabledKeys) : undefined,
    }));
  };

  // Handlers
  const handleCheckA = (checkedKeysValue: any) => {
    const checked = Array.isArray(checkedKeysValue) ? checkedKeysValue : checkedKeysValue.checked;
    const uncheckedKeys = checkedKeysA.filter((key) => !checked.includes(key));

    setCheckedKeysA(checked);
    const updatedKeysB = checkedKeysB.filter((key) => !uncheckedKeys.includes(key));
    setCheckedKeysB(updatedKeysB);
  };

  const handleCheckB = (checkedKeysValue: any) => {
    const checked = Array.isArray(checkedKeysValue) ? checkedKeysValue : checkedKeysValue.checked;
    const uncheckedKeys = checkedKeysB.filter((key) => !checked.includes(key));
    setCheckedKeysB(checked);

    const updatedKeysA = checkedKeysA.filter((key) => !uncheckedKeys.includes(key));
    setCheckedKeysA(updatedKeysA);
  };

  const onExpandA = (expandedKeysValue: Array<React.Key>) => {
    setExpandedKeysA(expandedKeysValue);
  };

  const onExpandB = (expandedKeysValue: Array<React.Key>) => {
    setExpandedKeysB(expandedKeysValue);
  };

  const handleSaveAll = () => {
    onSave({ treeA: checkedKeysA, treeB: checkedKeysB });
  };

  const adjustedDataSourceA = applyDisabledKeys(dataSourceA, checkedKeysB);
  const adjustedDataSourceB = applyDisabledKeys(dataSourceB, checkedKeysA);

  // Render
  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Translated id="areas.inReaders" />

          <StyledCard>
            <Tree
              checkable
              onExpand={onExpandA}
              expandedKeys={expandedKeysA}
              onCheck={handleCheckA}
              checkedKeys={checkedKeysA}
              selectable={false}
              treeData={adjustedDataSourceA}
            />
          </StyledCard>
        </Col>

        <Col span={12}>
          <Translated id="areas.outReaders" />

          <StyledCard>
            <Tree
              checkable
              onExpand={onExpandB}
              expandedKeys={expandedKeysB}
              onCheck={handleCheckB}
              checkedKeys={checkedKeysB}
              selectable={false}
              treeData={adjustedDataSourceB}
            />
          </StyledCard>
        </Col>
      </Row>
      <StyledButton type="primary" onClick={handleSaveAll}>
        <Translated id="form.confirmButton" />
      </StyledButton>
    </>
  );
};
