export enum ScheduleConfigurationJobType {
  ImportAzureAdUsers = 1,
  FetchInvitationEmails = 2,
  CheckOutVisitors = 3,
  RemoveRestrictedAccessProfiles = 4,
  DisableExpiredIdentifiers = 5,
}

export interface ScheduleConfigurationType {
  Name: string;
  Value: number;
  ScheduleConfiguration: ScheduleConfigurationJobType;
}

export const ScheduleConfigurationTypes: ScheduleConfigurationType[] = [
  {
    Name: 'Import Microsoft Entra AD users',
    Value: 1,
    ScheduleConfiguration: ScheduleConfigurationJobType.ImportAzureAdUsers,
  },
  {
    Name: 'Fetch invitation emails',
    Value: 2,
    ScheduleConfiguration: ScheduleConfigurationJobType.FetchInvitationEmails,
  },
  {
    Name: 'Checkout visitors',
    Value: 3,
    ScheduleConfiguration: ScheduleConfigurationJobType.CheckOutVisitors,
  },
  {
    Name: 'Remove restricted access profiles',
    Value: 4,
    ScheduleConfiguration: ScheduleConfigurationJobType.RemoveRestrictedAccessProfiles,
  },
  {
    Name: 'Disable expired identifiers',
    Value: 5,
    ScheduleConfiguration: ScheduleConfigurationJobType.DisableExpiredIdentifiers,
  },
];
