export enum AreaTypesEnum {
  Building = 1,
  Parking = 2,
}

export interface AreaType {
  Name: string;
  Value: number;
  AreaType: AreaTypesEnum;
}

export const AreaTypes: AreaType[] = [
  {
    Name: 'Building',
    Value: 0,
    AreaType: AreaTypesEnum.Building,
  },
  {
    Name: 'Parking',
    Value: 1,
    AreaType: AreaTypesEnum.Parking,
  },
];
