import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

// Models
import { Area } from '../../../models/Area';

// Hooks
import { useAreaTenantsRedux } from './useAreaTenantsRedux';
import { useData } from '../../App/useData';

// Data
import { ApiEndpoints } from '../../../data/ApiEndpoints';

// Props
interface AreaTenantsProps {
  areaResponse: Area | null;
  fetchArea: (params: any) => Promise<void>;
  updatingArea: boolean;
}

export const useAreaTenants = ({
  areaResponse: areaTenantsResponse,
  fetchArea,
  updatingArea: updatingAreaTenant,
}: AreaTenantsProps) => {
  // Data
  const { data: areaTenants, fetch: fetchAreaTenants, pending } = useData(ApiEndpoints.tenants.list, null);

  // Redux
  const { AreaTenantsManagementForm, updating, error } = useAreaTenantsRedux({
    areaTenantsResponse,
    areaTenants,
  });
  const prevUpdating = usePrevious(updating);

  // Component State
  const loading = pending && !areaTenantsResponse;

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetchAreaTenants();
  }, [fetchAreaTenants]);

  useEffect(() => {
    // Fetch after updating
    if (areaTenantsResponse && prevUpdating === true && updating === false && !error) {
      fetchArea({ id: areaTenantsResponse.Id });
      fetchAreaTenants();
    }
  }, [prevUpdating, updating, error, fetchArea, fetchAreaTenants, areaTenantsResponse]);

  // Return Hook
  return useMemo(
    () => ({
      areaTenants,
      AreaTenantsManagementForm,
      loading,
      updating: updating || updatingAreaTenant,
    }),
    [areaTenants, AreaTenantsManagementForm, loading, updating, updatingAreaTenant]
  );
};
