import React from 'react';

// Constants
import { Translated } from '../../components/UI/Core';
import { BreadcrumbIcon } from '../../components/UI/Icon/BreadcrumbIcon';
import { getBreadcrumbMenuItems } from '../../components/UI/Breadcrumb/BreadcrumbMenu';

export const appBreadcrumbs = {
  identityUsers: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.identityUsers" />,
        icon: <BreadcrumbIcon className="icon icon-auth-screen" />,
      },
    ]),
    detail: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.identityUsers" />,
        icon: <BreadcrumbIcon className="icon icon-auth-screen" />,
        link: '/IdentityUsers',
      },
      {
        label: <Translated id="app.pages.details" />,
        icon: <BreadcrumbIcon className="icon icon-ckeditor" />,
      },
    ]),
  },
  clients: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.apiClients" />,
        icon: <BreadcrumbIcon className="icon icon-user" />,
      },
    ]),
    detail: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.apiClients" />,
        icon: <BreadcrumbIcon className="icon icon-user" />,
        link: '/ApiClients',
      },
      {
        label: <Translated id="app.pages.details" />,
        icon: <BreadcrumbIcon className="icon icon-ckeditor" />,
      },
    ]),
  },
  tenants: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.tenants" />,
        icon: <BreadcrumbIcon className="icon icon-crm" />,
      },
    ]),
  },
  companies: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.companies" />,
        icon: <BreadcrumbIcon className="icon icon-company" />,
      },
    ]),
  },
  locations: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.locations" />,
        icon: <BreadcrumbIcon className="icon icon-location" />,
      },
    ]),
  },
  scheduleConfigurations: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.scheduleConfigurations" />,
        icon: <BreadcrumbIcon className="icon icon-schedule" />,
      },
    ]),
    detail: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.scheduleConfigurations" />,
        icon: <BreadcrumbIcon className="icon icon-schedule" />,
        link: '/ScheduleConfigurations',
      },
      {
        label: <Translated id="app.pages.details" />,
        icon: <BreadcrumbIcon className="icon icon-ckeditor" />,
      },
    ]),
  },
  serviceConfigurations: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.serviceConfigurations" />,
        icon: <BreadcrumbIcon className="icon icon-chart-radar" />,
      },
    ]),
    detail: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.serviceConfigurations" />,
        icon: <BreadcrumbIcon className="icon icon-chart-radar" />,
        link: '/ServiceConfigurations',
      },
      {
        label: <Translated id="app.pages.details" />,
        icon: <BreadcrumbIcon className="icon icon-ckeditor" />,
      },
    ]),
  },
  apiConfigurations: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.apiConfigurations" />,
        icon: <BreadcrumbIcon className="icon icon-chart-radar" />,
      },
    ]),
    detail: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.apiConfigurations" />,
        icon: <BreadcrumbIcon className="icon icon-chart-radar" />,
        link: '/ApiConfigurations',
      },
      {
        label: <Translated id="app.pages.details" />,
        icon: <BreadcrumbIcon className="icon icon-ckeditor" />,
      },
    ]),
  },
  accessProfiles: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.accessProfiles" />,
        icon: <BreadcrumbIcon className="icon icon-select" />,
      },
    ]),
    detail: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.accessProfiles" />,
        icon: <BreadcrumbIcon className="icon icon-select" />,
        link: '/AccessProfiles',
      },
      {
        label: <Translated id="app.pages.details" />,
        icon: <BreadcrumbIcon className="icon icon-ckeditor" />,
      },
    ]),
  },
  identifierTypes: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.identifierTypes" />,
        icon: <BreadcrumbIcon className="icon icon-product-list" />,
      },
    ]),
    detail: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.identifierTypes" />,
        icon: <BreadcrumbIcon className="icon icon-product-list" />,
        link: '/IdentifierTypes',
      },
      {
        label: <Translated id="app.pages.details" />,
        icon: <BreadcrumbIcon className="icon icon-ckeditor" />,
      },
    ]),
  },
  kiosks: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.kiosks" />,
        icon: <BreadcrumbIcon className="icon icon-apps" />,
      },
    ]),
    detail: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.kiosks" />,
        icon: <BreadcrumbIcon className="icon icon-apps" />,
        link: '/kiosks',
      },
      {
        label: <Translated id="app.pages.details" />,
        icon: <BreadcrumbIcon className="icon icon-ckeditor" />,
      },
    ]),
  },
  azureTenants: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.azureTenants" />,
        icon: <BreadcrumbIcon className="icon icon-auth-screen" />,
      },
    ]),
    detail: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.azureTenants" />,
        icon: <BreadcrumbIcon className="icon icon-auth-screen" />,
        link: '/AzureTenants',
      },
      {
        label: <Translated id="app.pages.details" />,
        icon: <BreadcrumbIcon className="icon icon-ckeditor" />,
      },
    ]),
  },
  calendarEventConfigurations: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.calendarEventConfigurations" />,
        icon: <BreadcrumbIcon className="icon icon-email" />,
      },
    ]),
    detail: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.calendarEventConfigurations" />,
        icon: <BreadcrumbIcon className="icon icon-email" />,
        link: '/CalendarEventConfigurations',
      },
      {
        label: <Translated id="app.pages.details" />,
        icon: <BreadcrumbIcon className="icon icon-ckeditor" />,
      },
    ]),
  },
  Actions: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.actions" />,
        icon: <BreadcrumbIcon className="icon icon-setting" />,
      },
    ]),
    detail: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.actions" />,
        icon: <BreadcrumbIcon className="icon icon-setting" />,
        link: '/Actions',
      },
      {
        label: <Translated id="app.pages.details" />,
        icon: <BreadcrumbIcon className="icon icon-ckeditor" />,
      },
    ]),
  },
  Areas: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.areas" />,
        icon: <BreadcrumbIcon className="icon icon-company" />,
      },
    ]),
    detail: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.areas" />,
        icon: <BreadcrumbIcon className="icon icon-company" />,
        link: '/Areas',
      },
      {
        label: <Translated id="app.pages.details" />,
        icon: <BreadcrumbIcon className="icon icon-ckeditor" />,
      },
    ]),
  },
  businessClients: {
    list: getBreadcrumbMenuItems([
      {
        label: <Translated id="appMenu.businessClients" />,
        icon: <BreadcrumbIcon className="icon icon-noodles" />,
      },
    ]),
  },
};
