import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Form, Input, Row, Select, Switch } from 'antd';
import { Store } from 'antd/lib/form/interface';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

// Types
import FormItemLabel from 'antd/lib/form/FormItemLabel';
import { FormOptions, UseFormProps } from '../../types/Table';

// Models
import { AccessProfile, AzureTenantAccessProfile } from '../../models/AccessProfile';

// Hooks
import { useAppDispatch, useAppSelector } from '../App/useRedux';

// Actions
import { updateAzureTenantAccessProfileConfiguration } from '../../store/AccessProfiles/AccessProfiles.redux';

// Components
import { Translated } from '../../components/UI/Core';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { AzureTenant } from '../../models/AzureTenant';
import { AzureGroups } from '../../components/AccessProfiles/AzureGroups';

import { AzureGroup } from '../../models/AzureGroup';
import { assignAzureGroupsAccessProfiles } from '../../store/AzureTenants/AzureGroups/AzureGroups.redux';

const { Item } = Form;
const { Option } = Select;

// Styled
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;

const NoMarginBottomFormItem = styled(NoSidePaddingFormItem)`
  margin-bottom: 0px;
`;

// Props
interface AccessProfilesReduxProps {
  initialValues?: Store;
  azureTenants?: AzureTenant[] | null;
  fetchAzureTenants?: () => Promise<void>;
  accessProfileData?: AccessProfile | null;
}

interface AssignConfirmProps {
  tenantAzure: AzureTenant;
  azureGroups: Array<AzureGroup>;
  accessProfiles?: AccessProfile | null;
}

// Hook
export const useAzureTenantGroupRedux = <T extends object>({
  initialValues,
  azureTenants,
  fetchAzureTenants,
  accessProfileData,
}: AccessProfilesReduxProps = {}) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ accessProfiles }) => accessProfiles?.updatingSettings ?? false);
  const error = useAppSelector(({ accessProfiles }) => accessProfiles?.error ?? false);
  const [selectedTenantId, setSelectedTenantId] = useState('');
  const [filterByAzureGroups, setFilterByAzureGroups] = useState(false);

  // States
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const initialSyncDone = useRef(false);

  // Data
  const azureTenant = azureTenants?.find(
    (x) => x.AzureTenantAccessProfiles.find((y) => y.AccessProfileId === initialValues?.Id) !== undefined
  );

  const filterGroup = azureTenants
    ?.find((tenant) =>
      tenant.AzureTenantAccessProfiles.some((profile) => profile.AccessProfileId === initialValues?.Id)
    )
    ?.AzureTenantAccessProfiles.find((profile) => profile.AccessProfileId === initialValues?.Id)?.FilterByAzureGroup;

  const azureTenantGroup = azureTenants?.find((x) => x.Id === selectedTenantId && filterByAzureGroups);

  useEffect(() => {
    if (azureTenant?.Id) {
      setSelectedTenantId(azureTenant.Id);
    }
    if (filterGroup) {
      setFilterByAzureGroups(filterGroup);
    }
  }, [azureTenant, filterGroup]);

  const assignGroup = useCallback(
    ({ tenantAzure, azureGroups, accessProfiles }: AssignConfirmProps) => {
      if (tenantAzure && azureGroups && accessProfiles) {
        dispatch(
          assignAzureGroupsAccessProfiles({
            accessProfileId: accessProfiles?.Id,
            azureGroups,
          })
        );
      }
    },
    [dispatch]
  );

  // Submit Handling
  const onSubmit = useCallback(
    async (accessProfile: any) => {
      const azureTenantAccessProfile: AzureTenantAccessProfile = {
        AccessProfileId: accessProfileData?.Id,
        AzureTenantId: accessProfile.AzureTenantId,
        FilterByAzureGroups: accessProfile.FilterByAzureGroups,
        FilterByAzureGroup: accessProfile.FilterByAzureGroups,
      };
      dispatch(updateAzureTenantAccessProfileConfiguration(azureTenantAccessProfile));

      if (azureTenant) {
        const selectedGroups = azureTenant.Groups.filter((x) => groupIds.includes(x.Id));
        assignGroup({
          tenantAzure: azureTenant,
          azureGroups: selectedGroups,
          accessProfiles: accessProfileData,
        });
      }
    },
    [accessProfileData, dispatch, azureTenant, assignGroup, groupIds]
  );

  useEffect(() => {
    if (azureTenant?.Groups && accessProfileData?.Id) {
      const matchingGroupIds = azureTenant.Groups.filter((group) =>
        group.AzureGroupAccessProfileIds?.includes(accessProfileData.Id)
      ).map((group) => group.Id);

      if (!initialSyncDone.current) {
        setGroupIds(matchingGroupIds);
        initialSyncDone.current = true; // Mark initial sync as done
      }
    }
  }, [accessProfileData?.Id, azureTenant?.Groups, groupIds]);

  const getAzureGroupsProps = useCallback(
    () => ({
      azureTenantResponse: azureTenantGroup,
      accessProfile: accessProfileData ?? null,
      fetchAzureTenants,
      groupIds,
      setGroupIds,
    }),
    [accessProfileData, azureTenantGroup, fetchAzureTenants, groupIds, setGroupIds]
  );

  // Form
  const CreateAccessProfileForm = useCallback(
    ({ form }: UseFormProps<T>) => (
      <>
        <StyledForm form={form} layout="vertical" onFinish={onSubmit}>
          <Spinner spinning={updating}>
            <Form.Item name="Id" hidden>
              <Input />
            </Form.Item>
            <Row>
              <Col className="col-6">
                <Item
                  initialValue={azureTenant?.Id}
                  name="AzureTenantId"
                  label={<Translated id="azure.microsoft.entra.id.configuration" />}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: 'azure.form.warnings.microsoft.entra.id' }),
                    },
                  ]}
                >
                  <Select
                    placeholder={<Translated id="azure.microsoft.entra.id.configuration" />}
                    onChange={(value) => setSelectedTenantId(value)}
                  >
                    {azureTenants?.map((tenant) => (
                      <Option key={tenant.Id} value={tenant.Id}>
                        {tenant.Name}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
              <Col className="col-6">
                <div style={{ marginBottom: 30 }}>
                  <FormItemLabel label={<Translated id="azure.FilterByAzureGroups" />} prefixCls="cls" />
                  <NoMarginBottomFormItem
                    name="FilterByAzureGroups"
                    valuePropName="checked"
                    initialValue={
                      azureTenant?.AzureTenantAccessProfiles.find((y) => y.AccessProfileId === initialValues?.Id)
                        ?.FilterByAzureGroup
                    }
                  >
                    <Switch
                      checkedChildren={<Translated id="pacsSetting.form.active" />}
                      unCheckedChildren={<Translated id="pacsSetting.form.disabled" />}
                      onChange={(checked) => setFilterByAzureGroups(checked)}
                    />
                  </NoMarginBottomFormItem>
                </div>
              </Col>
            </Row>
          </Spinner>
        </StyledForm>
        <AzureGroups {...getAzureGroupsProps()} />
      </>
    ),
    [
      azureTenant?.AzureTenantAccessProfiles,
      azureTenant?.Id,
      azureTenants,
      getAzureGroupsProps,
      initialValues?.Id,
      intl,
      onSubmit,
      updating,
    ]
  );
  // Form Options
  const formOptions = useMemo(
    () =>
      ({
        endpoint: 'AccessProfiles',
        Form: CreateAccessProfileForm,
        labels: {
          drawerForm: <Translated id="azure.entra.id.configure.drawer.title" />,
          submitButton: <Translated id="app.save" />,
        },
      } as FormOptions<T>),
    [CreateAccessProfileForm]
  );

  return useMemo(
    () => ({
      formOptions,
      updating,
      error,
    }),
    [formOptions, updating, error]
  );
};
