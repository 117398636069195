// Types
import { SignalREvent } from '../../../models/SignalREvent';

// Actions
import { areaTenantsAssigned, areaTenantsNotAssigned } from './AreaTenants.redux';

// Events
const SignalREvents = {
  AreaTenantsAssigned: `AreaTenantsAssignedIntegrationEvent`,
};

// Exceptions
const SignalRExceptions = {
  AreaTenantsNotAssigned: `AreaTenantsNotAssignedIntegrationException`,
};

// Events
export const configureSignalREvents = ({ hubConnection, store, history }: SignalREvent) => {
  // Assign
  hubConnection.on(SignalREvents.AreaTenantsAssigned, (msg) => {
    store.dispatch(areaTenantsAssigned({ history, msg }));
  });
  hubConnection.on(SignalRExceptions.AreaTenantsNotAssigned, (msg) => {
    store.dispatch(areaTenantsNotAssigned({ history, msg }));
  });
};
