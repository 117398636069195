import React from 'react';

import { TableColumn } from '../../types/Table';
import { Translated } from '../../components/UI/Core';
import { Tenant } from '../../models/Tenant';
import { BusinessClient } from '../../models/BusinessClient';
import { IdentityUserUnsafe } from '../../models/IdentityUserUnsafe';

interface IdentityUsersUnsafeTableColumnParams {
  tenants: Tenant[] | null;
  businessClients: BusinessClient[] | null;
}

type GetIdentityUsersUnsafeTableColumnProps = (
  params: IdentityUsersUnsafeTableColumnParams
) => Array<TableColumn<IdentityUserUnsafe>>;

export const getIdentityUserUnsafeTableColumns: GetIdentityUsersUnsafeTableColumnProps = ({
  tenants,
  businessClients,
}) => [
  {
    Header: <Translated id="identityUsers.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="identityUsers.name" />,
    id: 'UserName',
    accessor: 'UserName',
  },
  {
    Header: <Translated id="app.businessClient" />,
    id: 'BusinessClientId',
    accessor: (row) => businessClients?.find((x) => x.Id === row.BusinessClientId)?.Name,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="clients.clientTenant" />,
    id: 'TenantId',
    accessor: (row) => tenants?.find((x) => x.Id === row.TenantId)?.Name,
    disableFilters: true,
  },
];
