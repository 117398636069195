import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Modal } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

// Models
import styled from 'styled-components';
import { Area } from '../../../models/Area';
import { Tenant } from '../../../models/Tenant';

// Redux
import { useAppDispatch, useAppSelector } from '../../App/useRedux';
import { assignAreaTenants } from '../../../store/Areas/AreaTenants/AreaTenants.redux';

// Components
import { Translated } from '../../../components/UI/Core';
import { ItemTransfer, TransferItem } from '../../../components/UI/Transfer/ItemTransfer';

const { confirm } = Modal;

// Props
interface AreaTenantsFormProps {
  areaTenantsResponse: Area | null;
  areaTenants: Array<Tenant> | null;
}

interface AssignConfirmProps {
  tenants: Array<Tenant>;
  areaId: string;
}

// Styled
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;

// Hook
export const useAreaTenantsRedux = ({ areaTenantsResponse, areaTenants }: AreaTenantsFormProps) => {
  // Intl
  const intl = useIntl();

  // Redux
  const dispatch = useAppDispatch();
  const updating = useAppSelector(({ areas: areaTenantsState }) => areaTenantsState?.updating ?? false);
  const error = useAppSelector(({ areas: areaTenantsState }) => areaTenantsState?.error ?? false);

  // Confirmation Modals
  const showAssignConfirm = useCallback(
    ({ tenants, areaId }: AssignConfirmProps) => {
      if (tenants && areaId) {
        confirm({
          title: intl.formatMessage({
            id: 'areas.Tenants.multiSelect.confirm.assign',
          }),
          icon: <IdcardOutlined />,
          content: intl.formatMessage({
            id: 'areas.Tenants.multiSelect.confirm.assignSub',
          }),
          okText: intl.formatMessage({
            id: 'app.yes',
            defaultMessage: 'Yes',
          }),
          cancelText: intl.formatMessage({
            id: 'app.no',
            defaultMessage: 'No',
          }),
          okType: 'primary',
          onOk: () => dispatch(assignAreaTenants({ areaId, tenants })),
          onCancel: () => null,
        });
      }
    },
    [intl, dispatch]
  );

  // State
  const [tenantIds, setTenantIds] = useState<Array<string>>([]);

  // Sets the Keys when the response is updated
  // Fixes problem with initial values after API call
  useEffect(() => {
    setTenantIds(
      areaTenants
        ?.filter((tenant) =>
          areaTenantsResponse?.Tenants?.some(
            (areaTenant) =>
              areaTenant.CompanyId === tenant.CompanyId &&
              areaTenant.LocationId === tenant.LocationId &&
              areaTenant.BusinessClientId === tenant.BusinessClientId
          )
        )
        .map((tenant) => tenant.Id) ?? []
    );
  }, [areaTenants, areaTenantsResponse]);

  // Components
  const AreaTenantsManagementForm = useCallback(() => {
    // Get all Environments as MultiSelectItems
    const dataSource =
      areaTenants?.map<TransferItem>((areaTenant) => ({
        key: areaTenant.Id,
        title: areaTenant.Name,
        description: '',
      })) ?? [];

    return (
      <>
        <ItemTransfer dataSource={dataSource} targetKeys={tenantIds} setTargetKeys={setTenantIds} />
        <StyledButton
          type="primary"
          onClick={() =>
            areaTenantsResponse &&
            areaTenants &&
            showAssignConfirm({
              tenants: areaTenants.filter((t) => tenantIds.some((id) => id === t.Id)),
              areaId: areaTenantsResponse.Id,
            })
          }
        >
          <Translated id="form.confirmButton" />
        </StyledButton>
      </>
    );
  }, [areaTenantsResponse, areaTenants, showAssignConfirm, tenantIds, setTenantIds]);

  return useMemo(
    () => ({
      AreaTenantsManagementForm,
      updating,
      error,
    }),
    [AreaTenantsManagementForm, updating, error]
  );
};
