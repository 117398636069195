import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../services/ApiService/ApiService';
import { notificationService } from '../../services/Notifications/NotificationService';

// Models
import { IdentifierType } from '../../models/IdentifierType';
import { SignalRAction } from '../../models/SignalRAction';

// Redux
import {
  createIdentifierType,
  deleteIdentifierType,
  deleteIdentifierTypes,
  identifierTypeAdvanceSettingsNotUpdated,
  identifierTypeAdvanceSettingsUpdated,
  identifierTypeCreated,
  identifierTypeDeleted,
  identifierTypeNotCreated,
  identifierTypeNotDeleted,
  identifierTypeNotUpdated,
  identifierTypesDeleted,
  identifierTypeSettingsNotUpdated,
  identifierTypeSettingsUpdated,
  identifierTypesNotDeleted,
  identifierTypeUpdated,
  updateIdentifierType,
  updateIdentifierTypeAdvanceSettings,
  updateIdentifierTypeSettings,
} from './IdentifierTypes.redux';

// **************************************************
// ********************* CREATE *********************

// Worker Sagas
function* createIdentifierTypeSaga() {
  yield takeEvery(createIdentifierType.type, createIdentifierTypeRequest);
}

function* identifierTypeCreatedSaga() {
  yield takeLatest(identifierTypeCreated.type, createIdentifierTypeResponse);
}

function* identifierTypeNotCreatedSaga() {
  yield takeLatest(identifierTypeNotCreated.type, createIdentifierTypeError);
}

// Request
function* createIdentifierTypeRequest(action: PayloadAction<IdentifierType>) {
  try {
    const { payload: identifierType } = action;
    yield apiService.execute({
      url: 'IdentifierTypes',
      method: ApiRequestType.POST,
      data: identifierType,
    });
  } catch ({ message }) {
    yield put({ type: identifierTypeNotCreated.type, payload: { msg: { message } } });
  }
}

// Response
function createIdentifierTypeResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('identifierTypes.notifications.create');
  action.payload.history.push(`/IdentifierTypes/${action.payload.msg.entityId}`);
}

// Error
function createIdentifierTypeError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('identifierTypes.notifications.createFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPDATE *********************

// Worker Sagas
function* updateIdentifierTypeSaga() {
  yield takeEvery(updateIdentifierType.type, updateIdentifierTypeRequest);
}

function* identifierTypeUpdatedSaga() {
  yield takeLatest(identifierTypeUpdated.type, updateIdentifierTypeResponse);
}

function* identifierTypeNotUpdatedSaga() {
  yield takeLatest(identifierTypeNotUpdated.type, updateIdentifierTypeError);
}

// Request
function* updateIdentifierTypeRequest(action: PayloadAction<IdentifierType>) {
  try {
    const { payload: identifierType } = action;
    yield apiService.execute({
      url: `IdentifierTypes/${identifierType.Id}`,
      method: ApiRequestType.PUT,
      data: identifierType,
    });
  } catch ({ message }) {
    yield put({ type: identifierTypeNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateIdentifierTypeResponse() {
  notificationService.showSuccess('identifierTypes.notifications.update');
}

// Error
function updateIdentifierTypeError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('identifierTypes.notifications.updateFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE *********************

// Worker Sagas
function* deleteIdentifierTypeSaga() {
  yield takeEvery(deleteIdentifierType.type, deleteIdentifierTypeRequest);
}

function* identifierTypeDeletedSaga() {
  yield takeLatest(identifierTypeDeleted.type, deleteIdentifierTypeResponse);
}

function* identifierTypeNotDeletedSaga() {
  yield takeLatest(identifierTypeNotDeleted.type, deleteIdentifierTypeError);
}

// Request
function* deleteIdentifierTypeRequest(action: PayloadAction<IdentifierType>) {
  try {
    const { payload: identifierType } = action;
    yield apiService.execute({
      url: `IdentifierTypes/${identifierType.Id}`,
      method: ApiRequestType.DELETE,
      data: identifierType,
    });
  } catch ({ message }) {
    yield put({ type: identifierTypeNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteIdentifierTypeResponse(action: PayloadAction<SignalRAction>) {
  notificationService.showSuccess('identifierTypes.notifications.delete');
  action.payload.history.push(`/IdentifierTypes`);
}

// Error
function deleteIdentifierTypeError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('identifierTypes.notifications.deleteFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* DELETE All *****************

// Worker Sagas
function* deleteIdentifierTypesSaga() {
  yield takeEvery(deleteIdentifierTypes.type, deleteIdentifierTypesRequest);
}

function* identifierTypesDeletedSaga() {
  yield takeLatest(identifierTypesDeleted.type, deleteIdentifierTypesResponse);
}

function* identifierTypesNotDeletedSaga() {
  yield takeLatest(identifierTypesNotDeleted.type, deleteIdentifierTypesError);
}

// Request
function* deleteIdentifierTypesRequest(action: PayloadAction<Array<IdentifierType>>) {
  try {
    const { payload: identifierTypes } = action;
    yield apiService.execute({
      url: `IdentifierTypes`,
      method: ApiRequestType.DELETE,
      data: { ids: identifierTypes.map((identifierType) => identifierType.Id) },
    });
  } catch ({ message }) {
    yield put({ type: identifierTypesNotDeleted.type, payload: { msg: { message } } });
  }
}

// Response
function deleteIdentifierTypesResponse() {
  notificationService.showSuccess('identifierTypes.notifications.deleteAll');
}

// Error
function deleteIdentifierTypesError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent(
    'identifierTypes.notifications.deleteAllFailed',
    action?.payload?.msg.message
  );
}

// **************************************************
// ********************* UPDATE SETTINGS *********************

// Worker Sagas
function* updateIdentifierTypeSettingsSaga() {
  yield takeEvery(updateIdentifierTypeSettings.type, updateIdentifierTypeSettingsRequest);
}

function* identifierTypeSettingsUpdatedSaga() {
  yield takeLatest(identifierTypeSettingsUpdated.type, updateIdentifierTypeSettingsResponse);
}

function* identifierTypeSettingsNotUpdatedSaga() {
  yield takeLatest(identifierTypeSettingsNotUpdated.type, updateIdentifierTypeSettingsError);
}

// Request
function* updateIdentifierTypeSettingsRequest(action: PayloadAction<IdentifierType>) {
  try {
    const { payload: identifierType } = action;
    yield apiService.execute({
      url: `IdentifierTypes/${identifierType.Id}/Settings`,
      method: ApiRequestType.PUT,
      data: {
        Settings: identifierType,
      },
    });
  } catch ({ message }) {
    yield put({ type: identifierTypeSettingsNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateIdentifierTypeSettingsResponse() {
  notificationService.showSuccess('identifierTypes.notifications.update');
}

// Error
function updateIdentifierTypeSettingsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('identifierTypes.notifications.updateFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* UPDATE Advance SETTINGS *********************

// Worker Sagas
function* updateIdentifierTypeAdvanceSettingsSaga() {
  yield takeEvery(updateIdentifierTypeAdvanceSettings.type, updateIdentifierTypeAdvanceSettingsRequest);
}

function* identifierTypeAdvanceSettingsUpdatedSaga() {
  yield takeLatest(identifierTypeAdvanceSettingsUpdated.type, updateIdentifierTypeAdvanceSettingsResponse);
}

function* identifierTypeAdvanceSettingsNotUpdatedSaga() {
  yield takeLatest(identifierTypeAdvanceSettingsNotUpdated.type, updateIdentifierTypeAdvanceSettingsError);
}

// Request
function* updateIdentifierTypeAdvanceSettingsRequest(action: PayloadAction<IdentifierType>) {
  try {
    const { payload: identifierType } = action;
    yield apiService.execute({
      url: `IdentifierTypes/${identifierType.Id}/AdvancedSettings`,
      method: ApiRequestType.PUT,
      data: {
        Id: identifierType.Id,
        HasValidity: identifierType.HasValidity,
        Validity: identifierType.Validity,
        ValidityType: identifierType.ValidityType,
        OverrideValidity: identifierType.OverrideValidity,
      },
    });
  } catch ({ message }) {
    yield put({ type: identifierTypeAdvanceSettingsNotUpdated.type, payload: { msg: { message } } });
  }
}

// Response
function updateIdentifierTypeAdvanceSettingsResponse() {
  notificationService.showSuccess('identifierTypes.notifications.update');
}

// Error
function updateIdentifierTypeAdvanceSettingsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('identifierTypes.notifications.updateFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Create
    createIdentifierTypeSaga(),
    identifierTypeCreatedSaga(),
    identifierTypeNotCreatedSaga(),
    // Update
    updateIdentifierTypeSaga(),
    identifierTypeUpdatedSaga(),
    identifierTypeNotUpdatedSaga(),
    // Delete
    deleteIdentifierTypeSaga(),
    identifierTypeDeletedSaga(),
    identifierTypeNotDeletedSaga(),
    // Delete All
    deleteIdentifierTypesSaga(),
    identifierTypesDeletedSaga(),
    identifierTypesNotDeletedSaga(),
    // Update Settings
    updateIdentifierTypeSettingsSaga(),
    identifierTypeSettingsUpdatedSaga(),
    identifierTypeSettingsNotUpdatedSaga(),

    // Update Advance Settings
    updateIdentifierTypeAdvanceSettingsSaga(),
    identifierTypeAdvanceSettingsUpdatedSaga(),
    identifierTypeAdvanceSettingsNotUpdatedSaga(),
  ]);
}
