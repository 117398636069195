/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { Area, AreaReader } from '../../models/Area';
import { SignalRAction } from '../../models/SignalRAction';

// State
interface AreasState {
  updating: boolean;
  error: boolean;
  updatingEntityId?: string;
}

const initialState: AreasState = {
  updating: false,
  error: false,
  updatingEntityId: undefined,
};

// Name
const storeName = '@AREAS';

// Redux Actions|Reducers
const AreasSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    // Create
    createArea: (state, action: PayloadAction<Area>) => {
      state.updating = true;
      state.error = false;
    },
    areaCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    areaNotCreated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Update
    updateArea: (state, action: PayloadAction<Area>) => {
      state.updating = true;
      state.error = false;
    },
    areaUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    areaNotUpdated: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete
    deleteArea: (state, action: PayloadAction<Area>) => {
      state.updating = true;
      state.error = false;
    },
    areaDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    areaNotDeleted: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Delete All
    deleteAreas: (state, action: PayloadAction<Array<Area>>) => {
      state.updating = true;
      state.error = false;
    },

    // Assign Readers
    assignReaders: (state, action: PayloadAction<{ areaId: string; areaReaders: Array<AreaReader> }>) => {
      state.updating = true;
      state.error = false;
    },
    readersAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    readersNotAssigned: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },

    // Sync
    syncReaders: (state) => {
      state.updating = true;
      state.error = false;
    },
    readersSynced: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = false;
    },
    readersNotSynced: (state, action: PayloadAction<SignalRAction>) => {
      state.updating = false;
      state.error = true;
    },
  },
});

// Export Actions
export const {
  // Create
  createArea,
  areaCreated,
  areaNotCreated,
  // Update
  updateArea,
  areaUpdated,
  areaNotUpdated,
  // Delete
  deleteArea,
  areaDeleted,
  areaNotDeleted,
  // Delete All
  deleteAreas,
  // Assign Readers
  assignReaders,
  readersAssigned,
  readersNotAssigned,
  // Sync Readers
  syncReaders,
  readersSynced,
  readersNotSynced,
} = AreasSlice.actions;

// Export Reducer
export default AreasSlice.reducer;
