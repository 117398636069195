import { AuditOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Switch } from 'antd';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../hooks/App/useRedux';
import { AccessProfile, AccessProfileFormValues } from '../../models/AccessProfile';
import { Translated } from '../UI/Core';
import { Spinner } from '../UI/Spinner/Spinner';

import { Widget } from '../UI/Widget/Widget';
import { updateAccessProfileSettings } from '../../store/AccessProfiles/AccessProfiles.redux';
import { StyledTooltip } from '../UI/Tooltip/StyledTooltip';

const { Item } = Form;

interface ContactCardProps {
  accessProfile: AccessProfile | null;
  pending: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// Styled
const NoSidePaddingFormItem = styled(Item)`
  margin-bottom: 8px;

  & .ant-form-item-control {
    padding-left: 0;
    padding-right: 0;
  }
`;
const StyledButton = styled(Button)`
  margin-bottom: 0;
`;
const NoMarginBottomFormItem = styled(NoSidePaddingFormItem)`
  margin-bottom: 0;
`;

export const ContactCard = ({ accessProfile, pending, setOpen }: ContactCardProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { updatingSettings } = useAppSelector(({ accessProfiles }) => accessProfiles);

  // Form Values
  const initialValues: AccessProfileFormValues = useMemo(
    () => ({
      Id: accessProfile?.Id,
      Name: accessProfile?.Name,
      DefaultForVisits: accessProfile?.DefaultForVisits,
      DefaultForEmployees: accessProfile?.DefaultForEmployees,
      DefaultForAzureAdImport: accessProfile?.DefaultForAzureAdImport,
      DefaultForParkingReservations: accessProfile?.DefaultForParkingReservations,
      RequiresVerification: accessProfile?.RequiresVerification,
      Tenants: [],
    }),
    [accessProfile]
  );

  const SettingsForm = useCallback(
    () => (
      <Form
        layout="vertical"
        initialValues={initialValues}
        onFinish={(values: AccessProfile) => {
          dispatch(updateAccessProfileSettings(values));
        }}
      >
        {/* Hidden Values */}
        <Item name="Id" hidden>
          <Input />
        </Item>

        {/* Name */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <i className="icon icon-avatar gx-fs-xxl gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="accessProfiles.name" />
            </span>
            <div className="gx-mb-0">{initialValues?.Name}</div>
          </div>
        </div>

        {/* Use as Default for Visits */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <AuditOutlined className="gx-fs-xxl gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="accessProfiles.isDefaultForVisits" />
            </span>
            <div className="gx-mb-0 gx-mt-1">
              <NoMarginBottomFormItem name="DefaultForVisits" valuePropName="checked">
                <Switch checkedChildren={<Translated id="app.yes" />} unCheckedChildren={<Translated id="app.no" />} />
              </NoMarginBottomFormItem>
            </div>
          </div>
        </div>

        {/* Use as Default for Employees */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <AuditOutlined className="gx-fs-xxl gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="accessProfiles.isDefaultForEmployees" />
            </span>
            <div className="gx-mb-0 gx-mt-1">
              <NoMarginBottomFormItem name="DefaultForEmployees" valuePropName="checked">
                <Switch checkedChildren={<Translated id="app.yes" />} unCheckedChildren={<Translated id="app.no" />} />
              </NoMarginBottomFormItem>
            </div>
          </div>
        </div>

        {/* Use as Default for AzureAd Import */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <AuditOutlined className="gx-fs-xxl gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="accessProfiles.isDefaultForAzureAdImport" />
            </span>
            <div style={{ display: 'flex' }}>
              <div className="gx-mb-0 gx-mt-1 ml-4">
                <NoMarginBottomFormItem name="DefaultForAzureAdImport" valuePropName="checked">
                  <Switch
                    checkedChildren={<Translated id="app.yes" />}
                    unCheckedChildren={<Translated id="app.no" />}
                  />
                </NoMarginBottomFormItem>
              </div>

              {initialValues.DefaultForAzureAdImport && (
                <div className="gx-mb-0 gx-mt-2" style={{ marginLeft: '10px' }}>
                  <Button ghost type="primary" key="Edit" size="small" onClick={() => setOpen(true)}>
                    <span>
                      <Translated id="azure.entra.id.configure.button.text" defaultMessage="Edit" />
                    </span>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Use as Default for Parking Reservations */}
        <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
          <div className="gx-mr-3">
            <AuditOutlined className="gx-fs-xxl gx-text-grey" />
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">
              <Translated id="accessProfiles.isDefaultForParkingReservations" />
            </span>
            <div className="gx-mb-0 gx-mt-1">
              <NoMarginBottomFormItem name="DefaultForParkingReservations" valuePropName="checked">
                <Switch checkedChildren={<Translated id="app.yes" />} unCheckedChildren={<Translated id="app.no" />} />
              </NoMarginBottomFormItem>
            </div>
          </div>
        </div>

        {/* Requires verification */}
        <StyledTooltip title={<Translated id="accessProfiles.requiresVerificationTooltip" />} placement="left">
          <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
            <div className="gx-mr-3">
              <AuditOutlined className="gx-fs-xxl gx-text-grey" />
            </div>
            <div className="gx-media-body">
              <span className="gx-mb-0 gx-text-grey gx-fs-sm">
                <Translated id="accessProfiles.requiresVerification" />
              </span>
              <div className="gx-mb-0 gx-mt-1">
                <NoMarginBottomFormItem name="RequiresVerification" valuePropName="checked">
                  <Switch
                    checkedChildren={<Translated id="app.yes" />}
                    unCheckedChildren={<Translated id="app.no" />}
                  />
                </NoMarginBottomFormItem>
              </div>
            </div>
          </div>
        </StyledTooltip>

        <Divider />
        <StyledButton type="primary" htmlType="submit">
          <Translated id="form.confirmButton" />
        </StyledButton>
      </Form>
    ),
    [initialValues, dispatch, setOpen]
  );

  return (
    <Widget title={<Translated id="app.accessProfile" />} styleName="gx-card-profile-sm">
      <Spinner spinning={!!updatingSettings || pending}>
        <SettingsForm />
      </Spinner>
    </Widget>
  );
};
