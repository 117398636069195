import React from 'react';

import { TableColumn } from '../../types/Table';
import { Area } from '../../models/Area';
import { Translated } from '../../components/UI/Core';
import { AreaTypes } from '../../models/enums/AreaTypes';

export const AreaTableColumns: Array<TableColumn<Area>> = [
  {
    Header: <Translated id="areas.id" />,
    id: 'Id',
    accessor: 'Id',
    isVisible: false,
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: <Translated id="areas.name" />,
    id: 'Name',
    accessor: 'Name',
  },
  {
    Header: <Translated id="areas.type" />,
    id: 'Type',
    accessor: (row) => AreaTypes.find((type) => type.Value === row.AreaType)?.Name,
  },
];
