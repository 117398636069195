import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { usePrevious } from 'react-use';

// Hooks
import { useAreasRedux } from './useAreasRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Services
import { notificationService } from '../../services/Notifications/NotificationService';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';

// Props
interface AreaDetailProps {
  id?: string;
}

// Hook
export const useAreaDetail = <T extends object>({ id }: AreaDetailProps) => {
  // Navigation Hook
  const navigate = useNavigate();

  // Data
  const { data: areaResponse, fetch, pending } = useData(ApiEndpoints.areas.detail, null);
  const prevPending = usePrevious(pending);

  // Redux
  const { formOptions, updating, error, showDeleteConfirm, RenderReaderForm } = useAreasRedux<T>({
    isEditing: true,
    initialValues: areaResponse ?? {},
  });

  // Form Drawer
  const { getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.Areas.detail }), []);
  const getProfileHeaderProps = useCallback(
    () => ({
      area: areaResponse ?? null,
      pending,
      setOpen: getFormDrawerProps().setOpen,
      showDeleteConfirm,
    }),
    [areaResponse, pending, getFormDrawerProps, showDeleteConfirm]
  );
  const getContactCardProps = useCallback(
    () => ({
      area: areaResponse ?? null,
      pending,
    }),
    [areaResponse, pending]
  );
  const getAreaTenantsProps = useCallback(
    () => ({
      areaResponse,
      fetchArea: fetch,
      updatingArea: pending,
    }),
    [areaResponse, fetch, pending]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch({ id });
  }, [fetch, id]);

  useEffect(() => {
    // Navigates to the index if not found
    if (prevPending === true && pending === false && !areaResponse) {
      notificationService.showError('areas.notFound');
      navigate('/Areas');
    }
  }, [prevPending, pending, areaResponse, navigate]);

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getAreaTenantsProps,
      RenderReaderForm,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getAreaTenantsProps,
      RenderReaderForm,
    ]
  );
};
