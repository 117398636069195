import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

// Services
import { ApiRequestType, apiService } from '../../../services/ApiService/ApiService';
import { notificationService } from '../../../services/Notifications/NotificationService';

// Models
import { SignalRAction } from '../../../models/SignalRAction';

// Redux
import {
  assignAzureGroups,
  assignAzureGroupsAccessProfiles,
  azureGroupsAccessProfilesAssigned,
  azureGroupsAccessProfilesNotAssigned,
  azureGroupsAssigned,
  azureGroupsNotAssigned,
  azureGroupsNotSynced,
  azureGroupsSynced,
  syncAzureGroups,
} from './AzureGroups.redux';
import { AzureTenant } from '../../../models/AzureTenant';
import { AzureGroup } from '../../../models/AzureGroup';

// **************************************************
// ********************* ASSIGN *********************

// Worker Sagas
function* assignAzureGroupsSaga() {
  yield takeEvery(assignAzureGroups.type, assignAzureGroupsRequest);
}

function* azureGroupsAssignedSaga() {
  yield takeLatest(azureGroupsAssigned.type, assignAzureGroupsResponse);
}

function* azureGroupsNotAssignedSaga() {
  yield takeLatest(azureGroupsNotAssigned.type, assignAzureGroupsError);
}

// Request
function* assignAzureGroupsRequest(
  action: PayloadAction<{ azureTenant: AzureTenant; azureGroups: Array<AzureGroup> }>
) {
  try {
    const {
      payload: { azureTenant, azureGroups },
    } = action;
    yield apiService.execute({
      url: `AzureTenants/${azureTenant.Id}/AssignGroups`,
      method: ApiRequestType.PUT,
      data: {
        AzureTenantId: azureTenant.Id,
        AzureGroupsIds: azureGroups.map((x) => x.Id),
      },
    });
  } catch ({ message }) {
    yield put({ type: azureGroupsNotAssigned.type, payload: { msg: { message } } });
  }
}

// Response
function assignAzureGroupsResponse() {
  notificationService.showSuccess('azureTenants.notifications.assign');
}

// Error
function assignAzureGroupsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('azureTenants.notifications.assignFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* SYNC ***********************

// Worker Sagas
function* syncAzureGroupsSaga() {
  yield takeEvery(syncAzureGroups.type, syncAzureGroupsRequest);
}

function* azureGroupsSyncedSaga() {
  yield takeLatest(azureGroupsSynced.type, syncAzureGroupsResponse);
}

function* azureGroupsNotSyncedSaga() {
  yield takeLatest(azureGroupsNotSynced.type, syncAzureGroupsError);
}

// Request
function* syncAzureGroupsRequest(action: PayloadAction<{ azureTenant: AzureTenant }>) {
  try {
    const {
      payload: { azureTenant },
    } = action;
    yield apiService.execute({
      url: `AzureTenants/${azureTenant.Id}/SyncGroups`,
      method: ApiRequestType.POST,
      data: {},
    });
  } catch ({ message }) {
    yield put({ type: azureGroupsNotSynced.type, payload: { msg: { message } } });
  }
}

// Response
function syncAzureGroupsResponse() {
  notificationService.showSuccess('azureTenants.notifications.synced');
}

// Error
function syncAzureGroupsError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('azureTenants.notifications.syncFailed', action?.payload?.msg.message);
}

// **************************************************
// ********************* ASSIGN *********************

// Worker Sagas
function* assignAzureGroupsAccessProfilesSaga() {
  yield takeEvery(assignAzureGroupsAccessProfiles.type, assignAzureGroupsAccessProfilesRequest);
}

function* azureGroupsAccessProfilesAssignedSaga() {
  yield takeLatest(azureGroupsAccessProfilesAssigned.type, assignAzureGroupsAccessProfilesResponse);
}

function* azureGroupsAccessProfilesNotAssignedSaga() {
  yield takeLatest(azureGroupsAccessProfilesNotAssigned.type, assignAzureGroupsAccessProfilesError);
}

// Request
function* assignAzureGroupsAccessProfilesRequest(
  action: PayloadAction<{ accessProfileId: any; azureGroups: Array<AzureGroup> }>
) {
  try {
    const {
      payload: { accessProfileId, azureGroups },
    } = action;

    yield apiService.execute({
      url: `AccessProfiles/${accessProfileId}/AssignAzureGroups`,
      method: ApiRequestType.PUT,
      data: {
        AccessProfileId: accessProfileId,
        AzureGroupsIds: azureGroups.map((x) => x.Id),
      },
    });
  } catch ({ message }) {
    yield put({ type: azureGroupsAccessProfilesNotAssigned.type, payload: { msg: { message } } });
  }
}

// Response
function assignAzureGroupsAccessProfilesResponse() {
  notificationService.showSuccess('azureTenants.notifications.assign');
}

// Error
function assignAzureGroupsAccessProfilesError(action: PayloadAction<SignalRAction>) {
  notificationService.showErrorWithContent('azureTenants.notifications.assignFailed', action?.payload?.msg.message);
}

// **************************************************

// ********************* EXPORT SAGAS ***************

export default function* sagas() {
  yield all([
    // Assign
    assignAzureGroupsSaga(),
    azureGroupsAssignedSaga(),
    azureGroupsNotAssignedSaga(),
    // Sync
    syncAzureGroupsSaga(),
    azureGroupsSyncedSaga(),
    azureGroupsNotSyncedSaga(),
    // Assign
    assignAzureGroupsAccessProfilesSaga(),
    azureGroupsAccessProfilesAssignedSaga(),
    azureGroupsAccessProfilesNotAssignedSaga(),
  ]);
}
