import React from 'react';
import { Col, Row } from 'antd';

// Hooks
import { useParams } from 'react-router';
import { useAccessProfileDetail } from '../../hooks/AccessProfiles/useAccessProfileDetail';

// Components
import { Container } from '../../components/UI/Base';
import { AccessProfileTenants, ContactCard, PACSAccessProfiles, ProfileHeader } from '../../components/AccessProfiles';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { FormDrawer } from '../../components/UI/Drawer/FormDrawer';
import { BreadcrumbMenu } from '../../components/UI/Breadcrumb/BreadcrumbMenu';

export const AccessProfileDetailPage = () => {
  const { id } = useParams();
  const {
    updating,
    getBreadcrumbMenuProps,
    getProfileHeaderProps,
    getContactCardProps,
    getFormDrawerProps,
    getAccessProfileTenantsProps,
    getPACSAccessProfilesProps,
    getFormAzureGroupDrawerProps,
  } = useAccessProfileDetail({
    id,
  });

  return (
    <Spinner spinning={updating}>
      <BreadcrumbMenu {...getBreadcrumbMenuProps()} />

      <ProfileHeader {...getProfileHeaderProps()} />

      <Container className="gx-profile-content">
        <Row>
          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <AccessProfileTenants {...getAccessProfileTenantsProps()} />
          </Col>
          <Col xl={8} lg={10} md={10} sm={24} xs={24}>
            <ContactCard {...getContactCardProps()} />
          </Col>
          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <PACSAccessProfiles {...getPACSAccessProfilesProps()} />
          </Col>
        </Row>
      </Container>
      <FormDrawer {...getFormDrawerProps()} />

      <FormDrawer {...getFormAzureGroupDrawerProps()} />
    </Spinner>
  );
};
