import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Store } from 'antd/lib/form/interface';
import { usePrevious } from 'react-use';

// Hooks
import { useAccessProfilesRedux } from './useAccessProfilesRedux';
import { useFormDrawer } from '../Drawer/useFormDrawer';
import { useData } from '../App/useData';

// Services
import { notificationService } from '../../services/Notifications/NotificationService';

// Data
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { useAppSelector } from '../App/useRedux';
import { appBreadcrumbs } from '../../data/Breadcrumbs/Breadcrumbs';
import { useAzureTenantGroupRedux } from './useAzureTenantGroupRedux';

// Props
interface AccessProfileDetailProps {
  id?: string;
}

// Hook
export const useAccessProfileDetail = <T extends object>({ id }: AccessProfileDetailProps) => {
  // Navigation Hook
  const navigate = useNavigate();

  // Redux State
  const { updatingSettings } = useAppSelector(({ accessProfiles }) => accessProfiles);
  const prevUpdatingSettings = usePrevious(updatingSettings);

  // Data
  const { data: accessProfileResponse, fetch, pending } = useData(ApiEndpoints.accessProfiles.detail, null);
  const prevPending = usePrevious(pending);
  const { data: azureTenants, fetch: fetchAzureTenants } = useData(ApiEndpoints.azureTenants.list, null);

  // Redux
  const { formOptions, updating, error, showDeleteConfirm } = useAccessProfilesRedux<T>({
    isEditing: true,
    initialValues: accessProfileResponse?.AccessProfile as Store,
  });

  const {
    formOptions: azureGroupFormOptions,
    updating: azureGroupUpdating,
    error: azureGroupError,
  } = useAzureTenantGroupRedux<T>({
    azureTenants,
    initialValues: accessProfileResponse?.AccessProfile as Store,
    accessProfileData: accessProfileResponse?.AccessProfile,
  });

  const prevUpdating = usePrevious(updating);
  const prevAzureGroupUpdating = usePrevious(azureGroupUpdating);

  // Form Drawer
  const { getFormDrawerProps } = useFormDrawer<T>({ formOptions, updating, error });

  const { getFormDrawerProps: getFormAzureGroupDrawerProps } = useFormDrawer<T>({
    formOptions: azureGroupFormOptions,
    updating: azureGroupUpdating,
    error: azureGroupError,
    size: 'large',
  });

  // Props
  const getBreadcrumbMenuProps = useCallback(() => ({ breadcrumbs: appBreadcrumbs.accessProfiles.detail }), []);

  const getProfileHeaderProps = useCallback(
    () => ({
      accessProfile: accessProfileResponse?.AccessProfile ?? null,
      pending,
      setOpen: getFormDrawerProps().setOpen,
      showDeleteConfirm,
    }),
    [accessProfileResponse, pending, getFormDrawerProps, showDeleteConfirm]
  );

  const getContactCardProps = useCallback(
    () => ({
      accessProfile: accessProfileResponse?.AccessProfile ?? null,
      pending,
      setOpen: getFormAzureGroupDrawerProps().setOpen,
    }),
    [accessProfileResponse?.AccessProfile, getFormAzureGroupDrawerProps, pending]
  );

  const getAccessProfileTenantsProps = useCallback(
    () => ({
      accessProfileResponse,
      fetchAccessProfile: fetch,
      updatingAccessProfile: pending,
    }),
    [accessProfileResponse, fetch, pending]
  );
  const getPACSAccessProfilesProps = useCallback(
    () => ({
      accessProfileResponse,
      fetchAccessProfile: fetch,
      updatingAccessProfile: pending,
    }),
    [accessProfileResponse, fetch, pending]
  );

  // Effects
  useEffect(() => {
    // Fetch on initializing
    fetch({ id });
    fetchAzureTenants();
  }, [fetch, id, fetchAzureTenants]);

  useEffect(() => {
    // Navigates to the index if not found
    if (prevPending === true && !pending && !accessProfileResponse) {
      notificationService.showError('accessProfiles.notFound');
      navigate('/AccessProfiles');
    }
  }, [prevPending, pending, accessProfileResponse, navigate]);

  useEffect(() => {
    // Fetch after updating
    if (
      ((prevUpdating === true && !updating) ||
        (prevUpdatingSettings === true && updatingSettings === false) ||
        (prevAzureGroupUpdating === true && !azureGroupUpdating)) &&
      !error
    ) {
      fetch({ id });
      fetchAzureTenants();
    }
  }, [
    prevUpdating,
    updating,
    prevUpdatingSettings,
    updatingSettings,
    error,
    fetch,
    id,
    fetchAzureTenants,
    prevAzureGroupUpdating,
    azureGroupUpdating,
  ]);

  return useMemo(
    () => ({
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getPACSAccessProfilesProps,
      getAccessProfileTenantsProps,
      getFormAzureGroupDrawerProps,
    }),
    [
      updating,
      getBreadcrumbMenuProps,
      getProfileHeaderProps,
      getContactCardProps,
      getFormDrawerProps,
      getPACSAccessProfilesProps,
      getAccessProfileTenantsProps,
      getFormAzureGroupDrawerProps,
    ]
  );
};
